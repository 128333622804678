export default {
  data(){
    return {
      debounce    : null
    };
  },
  methods: {

    throwError( error ){

      var component = this;

      // NOTIFY ERROR
      component.$store.commit( 'notifyError', error );

      // RESET PROCESSING
      component.$store.commit( 'setProcessing', false );
    },
    debounceEvent( callback ) {
      var component = this;
			clearTimeout( component.debounce );
      component.debounce = setTimeout(() => {
				callback();
			}, 600);
    }
  }
};
