<template>
  <div class="text-gray" v-if='choice && choice.label'>
    <Icon name="check-circle" class="w-4 h-4 inline" />
    {{ choice.label }}
  </div>
</template>

<script>
import Icon from "@/components/Icons.vue";

export default{
  props:{
    choice: Object
  },
  components:{
    Icon
  }
}
</script>
