<template>
  <div class="prompt-grid grid pt-8">
    <PromptItem
      v-for="post in posts"
      :key="post.id"
      :post="post"
      class="prompt-grid-item relative overflow-hidden text-white font-medium"
    />
  </div>
</template>

<script>
import DefaultMixin from "@/mixins/DefaultMixin.js";
import PromptItem from "@/templates/prompt/PromptItem.vue";
//import PromptTitle from "@/components/prompt/PromptTitle.vue";

export default {
  name: "PromptGrid",
  props: {
    posts: Array,
  },
  mixins: [DefaultMixin],
  components: {
    PromptItem,
  },
};
</script>

<style></style>
