<template>
  <div class="border border-light-gray rounded p-4">
    <div class="flex flex-1 space-x-2 md:space-x-4 mb-4">
      <OrbitAuthor
        v-if="$store?.state?.localSettings?.id"
        class="h-12 w-12"
        :params="{
          id: $store?.state?.localSettings?.id,
          style: 'AuthorAvatar',
        }"
      />
      <router-link
        v-else
        to="/me"
        class="author-avatar h-12 w-12 h-12 w-12 inline-block"
      >
        <img
          src="https://graph.facebook.com/10209324780265291/picture?width=150&amp;height=150"
          class="rounded-full w-full h-full object-cover"
        />
      </router-link>
      <router-link
        class="bg-lightest-gray w-full rounded-full p-2.5 px-4"
        to="/me"
      >
        <Icon name="writing" class="h-4 w-4 md:h-5 md:w-5 inline mr-1" />
        What is on your mind?
      </router-link>
    </div>
    <div
      class="text-sm md:text-base border-t border-light-gray pt-2 flex space-x-4 justify-between sm:justify-center"
    >
      <router-link
        class="md:p-2 hover:text-orange transition-colors duration-300 ease-in"
        to="/me"
      >
        <Icon name="writing" class="h-4 w-4 md:h-5 md:w-5 inline" />
        Write Article
      </router-link>
      <router-link
        class="md:p-2 hover:text-orange transition-colors duration-300 ease-in"
        to="/create-poll"
      >
        <Icon name="polls" class="h-3 w-3 md:h-4 md:w-4 inline" />
        Create Poll
      </router-link>
      <router-link
        class="md:p-2 hover:text-orange transition-colors duration-300 ease-in"
        to="/search"
      >
        <Icon name="search" class="h-4 w-4 md:h-5 md:w-5 inline" />
        Search
      </router-link>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icons.vue";
import OrbitAuthor from "@/lib/OrbitAuthor.vue";

export default {
  components: {
    Icon,
    OrbitAuthor,
  },
};
</script>
