<template>
  <div
    class="yka-cpt-poll lg:-mx-4 lg:flex flex-wrap"
    v-if="'success' == status"
  >
    <template v-for="item in posts" :key="item.id">
      <div
        v-if="item.poll_id != null"
        class="mb-6 flex notes lg:w-1/3 lg:p-4 justify-center"
      >
        <PollFeedItem :id="item.poll_id" :post="item" />
      </div>
    </template>
  </div>
  <div class="yka-cpt-poll lg:-mx-4 lg:flex flex-wrap" v-else>
    <PollGridAnimation />
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import PollGridAnimation from "../components/animation/PollGridAnimation.vue";

const PollFeedItem = defineAsyncComponent(() =>
  import(/* webpackChunkName: "Chunks2" */ "@/templates/poll/PollFeedItem.vue")
);

export default {
  name: "PollFeedThreeGrid",
  components: {
    PollFeedItem,
    PollGridAnimation,
  },
  props: {
    posts: Array,
    status: String,
  },
};
</script>
