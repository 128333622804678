import API from "@/api.js";
import CommentUtil from "../CommentUtil";

export default {
  namespaced: true,
  state: {
    parentCommentId: 0,
    currentPostID: 0,
    comments: [],
    showError: false,
    showCommentModal: false,
    showPendingMessage: false,
  },
  mutations: {
    setComments(state, payload) {
      state.comments = payload;
    },
    setParentCommentId(state, comment) {
      state.parentCommentId = comment;
    },
    setPostID(state, postID) {
      state.currentPostID = postID;
    },
    showError(state, payload) {
      state.showError = payload;
    },
    setSelectedUser(state, user) {
      state.selectedUser = user;
    },
    showCommentModal(state, payload) {
      state.showCommentModal = payload;
    },
    setshowPendingMessage(state, payload) {
      state.showPendingMessage = payload;
    },
  },
  actions: {
    getComments({ commit }, post_id) {
      API.requestComments(post_id, {
        per_page: 100,
      }).then(
        (response) => {
          const comments = CommentUtil.arrangeComments(response.data);
          commit("setComments", comments);
        },
        (error) => {
          console.log(error.response.data.message);
        }
      );
    },
  },
};
