<template>
  <div>

    <AuthorAvatar
      class="author-avatar h-20 w-20 mb-4"
      :author="author"
      :status="status"
    />

    <div class='flex flex-wrap justify-between'>
      <div class="text-xl block w-9/12">
        Written by <AuthorName class="font-bold" :author="author" :status="status" />
        <div class="text-sm md:text-lg text-gray font-light" v-if="author.follow">{{ author.follow.no_followers }} Followers</div>
        <div
          class="text-sm md:text-lg font-light py-1 line-clamp-4 md:line-clamp-6"
          v-html="author.description"
        ></div>

      </div>
      <div class="w-3/12">
        <FollowBtn
          v-if="!isCurrentUser() && author.follow"
          class="rounded-button float-right"
          :follow="author.follow.is_following"
          object_type="user"
          :object_id="author.id"
          @followBtnClicked="followBtnClicked"
        />
      </div>
    </div>



  </div>
</template>

<script>
import AuthorName from "@/templates/author/Name.vue";
import AuthorAvatar from "@/templates/author/Avatar.vue";
import FollowBtn from "@/lib/FollowBtn.vue";



import store from '@/store';

//import { ref, onMounted, watch } from 'vue';

export default {
  props: {
    author: Object,
    status: String,
  },
  components: {
    AuthorAvatar,
    AuthorName,
    FollowBtn

  },


  setup( props, { emit } ){



    /*
    * GET CURRENT USER ID
    */
    const getCurrentUserID = () =>
      store.state.localSettings && store.state.localSettings.id ?
      store.state.localSettings.id : 0;

    /*
    * RETURNS BOOLEAN FLAG IF THE LOGGED IN USER
    * IS VIEWING ITS OWN PROFILE
    */
    const isCurrentUser = () =>
      props.author && props.author.id && props.author.id == getCurrentUserID() ?
      true : false;


    const followBtnClicked = ( followFlag ) => {

      /*
      cloneAuthor.value.follow.no_followers = parseInt( cloneAuthor.value.follow.no_followers );
      if ( followFlag )
        cloneAuthor.value.follow.no_followers++;
      else if ( cloneAuthor.value.follow.no_followers > 0 )
        cloneAuthor.value.follow.no_followers--;
      */

      //emit( 'followBtnClicked', cloneAuthor.value.follow.no_followers )

      console.log( followFlag )

      emit( "test" )

    }



    return{
      getCurrentUserID,
      isCurrentUser,
      followBtnClicked
    }

  }
};
</script>
