<template>
  <div class="md:px-2 px-0">
    <!-- Tabs Navigation with Arrows -->
    <div class="relative flex items-center outer-box">
      <!-- Left Arrow -->
      <button
        v-if="canScrollLeft"
        @click="scrollLeft"
        class="absolute left-0 z-10 flex items-center justify-center pr-8 arrow-btn-left"
      >
        <Icon name="arrow-left" class="text-gray" />
      </button>

      <!-- Tabs Container -->
      <div
        ref="tabsContainer"
        class="flex space-x-4 overflow-x-auto no-scrollbar transition-all duration-300 w-full"
        :class="canScrollLeft ? 'ml-8' : 'ml-0'"
        @scroll="handleScroll"
      >
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          class="tab px-2 py-2 pb-3 text-sm font-medium text-center whitespace-nowrap cursor-pointer"
          :class="{
            'border-b-2 border-yka-blue ': selectedTab.id === tab.id,
            'border-b-2 border-lighter-gray bg-gray-100 text-gray':
              selectedTab !== tab,
          }"
          @click="selectTab(tab)"
        >
          {{ tab.name }}
        </div>
      </div>

      <!-- Right Arrow -->
      <button
        v-if="canScrollRight"
        @click="scrollRight"
        class="absolute right-0 z-10 flex items-center justify-center pl-8 arrow-btn-right"
      >
        <Icon name="arrow-right" class="text-gray" />
      </button>
    </div>

    <!-- Tab Content Section -->
    <div class="mx-2 mt-4">
      <Feed v-if="style === 'Feed'" :params="getParams" :key="getParams" />
      <OrbitPosts v-else :params="getParams" :key="getParams">
        <template v-slot:whenempty>
          <div class="mb-8 mt-2 text-center">
            No posts found in {{ selectedTab?.name }} category!
          </div>
        </template>
      </OrbitPosts>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, onUnmounted, watch, computed } from "vue";
import Icon from "@/components/Icons.vue";
import { useRoute, useRouter } from "vue-router";

export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    style: String,
    categories_exclude: String,
  },
  components: { Icon },
  setup(props) {
    const selectedTab = ref(props.tabs ? props.tabs[0] : null);
    const canScrollLeft = ref(false);
    const canScrollRight = ref(false);
    const tabsContainer = ref(null);
    const router = useRouter();
    const route = useRoute();

    const apiParams = computed(() => ({
      categories: selectedTab.value?.id ?? null,
      style: props?.style,
      categories_exclude: props?.categories_exclude,
      pagination: 1,
    }));

    const getParams = ref(apiParams.value);

    watch(selectedTab, () => {
      getParams.value = apiParams.value;
    });

    const syncTabWithUrl = () => {
      const tabIdFromUrl = parseInt(route.query.term_id);
      const matchedTab = props.tabs.find((tab) => tab.id === tabIdFromUrl);
      if (matchedTab) {
        selectedTab.value = matchedTab;
      } else if (props.tabs.length) {
        selectedTab.value = props.tabs[0];
      }
      checkScrollState();
    };

    const selectTab = (item) => {
      selectedTab.value = item;
      router.push({ query: { ...route.query, term_id: item.id } });
    };

    const scrollLeft = () => {
      tabsContainer.value.scrollBy({
        left: -250,
        behavior: "smooth",
      });
    };

    const scrollRight = () => {
      tabsContainer.value.scrollBy({
        left: 250,
        behavior: "smooth",
      });
    };

    const handleScroll = () => {
      checkScrollState();
    };

    const checkScrollState = () => {
      nextTick(() => {
        const { scrollWidth, clientWidth, scrollLeft } = tabsContainer.value;
        canScrollLeft.value = scrollLeft > 0;
        canScrollRight.value = scrollLeft + clientWidth < scrollWidth;
      });
    };

    onMounted(() => {
      syncTabWithUrl();
      checkScrollState();
      window.addEventListener("resize", checkScrollState);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", checkScrollState);
    });

    watch(() => route.query.term_id, syncTabWithUrl);

    watch(
      () => props.tabs,
      () => {
        syncTabWithUrl();
      },
      { immediate: true }
    );

    return {
      selectedTab,
      canScrollLeft,
      canScrollRight,
      tabsContainer,
      selectTab,
      scrollLeft,
      scrollRight,
      handleScroll,
      getParams,
    };
  },
};
</script>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.border-bottom-line {
  border-bottom-width: 2px;
  margin-top: -2px;
  border-color: rgb(232 230 230);
}

.arrow-btn-right {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.75) 25%,
    rgba(255, 255, 255, 0.9) 50%,
    rgb(255, 255, 255) 75%
  );
}

.arrow-btn-left {
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.75) 25%,
    rgba(255, 255, 255, 0.9) 50%,
    rgb(255, 255, 255) 75%
  );
}

.outer-box {
  box-shadow: rgb(221, 221, 221) 0px -2px 0px inset;
}
</style>
