<template>
  <div v-for="index in items" :key="index" :class="containerClass">
    <div class="w-full">
      <div class="grid grid-cols-3 gap-4 mb-4">
        <div
          class="h-10 animate-pulse bg-lighter-gray rounded col-span-1"
        ></div>
      </div>
      <div class="h-4 animate-pulse bg-lighter-gray rounded mb-4"></div>
      <div class="w-full animate-pulse">
        <div class="flex-1 space-y-6 py-1">
          <div class="grid grid-cols-3 gap-4">
            <div class="h-2 bg-lighter-gray rounded col-span-1"></div>
          </div>
          <div class="space-y-3">
            <div class="h-8 bg-lighter-gray rounded"></div>
            <div class="h-8 bg-lighter-gray rounded"></div>
            <div class="h-8 bg-lighter-gray rounded"></div>
          </div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-lighter-gray rounded col-span-2"></div>
              <div class="h-2 bg-lighter-gray rounded col-span-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PollGridAnimation",
  props: {
    containerClass: {
      type: String,
      default() {
        return "mb-6 flex notes lg:w-1/3 lg:p-4";
      },
    },
    items: {
      type: Number,
      default() {
        return 3;
      },
    },
  },
};
</script>
