<template>
  <header class="bg-white sticky top-0 z-50 shadow-lg lg:shadow-none">
    <nav>
      <TopBar />
    </nav>
  </header>
</template>

<script>
import TopBar from "@/components/header/TopBar.vue";

export default {
  components: {
    TopBar,
  },
};
</script>
