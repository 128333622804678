<template>
  <div class="yka-cpt-poll lg:-mx-4 lg:flex flex-wrap">
    <template v-for="item in posts" :key="item.id">
      <div
        v-if="item.poll_id != null"
        class="mb-6 flex notes lg:w-1/2 lg:p-4"
      >
        <PollFeedItem :id="item.poll_id" :post="item" />
      </div>
    </template>
  </div>
</template>
<script>
//import PollFeedItem from "@/templates/poll/PollFeedItem.vue";

import { defineAsyncComponent } from "vue";

const PollFeedItem = defineAsyncComponent(() =>
  import( /* webpackChunkName: "Chunks2" */ "@/templates/poll/PollFeedItem.vue" )
);

export default {
  name: "PollFeedTwoGrid",
  components: {
    PollFeedItem,
  },
  props: {
    posts: Array,
  },
};
</script>
