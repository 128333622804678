<template>
  <div class="posts-list" v-if="posts.length">
    <Card
      v-for="post in published"
      :key="post.id"
      :subtitle="getSubtitle(post)"
      :badge="badgeText(post)"
    >
      <template v-slot:cardheader>
        <router-link :to="getPostLink(post)">
          <h2
            class="inline-block font-bold text-left my-2 text-black text-2xl lg:text-2xl"
            v-html="post.title.rendered"
          ></h2>
        </router-link>
      </template>
      <template v-slot:cardcontent v-if="canUserModify">
        <div class="mt-4">
          <EditPostActions :post="post" :key="post" @deletePost="deletePost" />
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import Util from "@/lib/Util";
import Card from "@/lib/Card.vue";
import EditPostActions from "@/templates/post-list/EditPostActions.vue";

export default {
  props: {
    posts: Array,
    status: String,
  },
  components: {
    Card,
    EditPostActions,
  },
  data() {
    return { published: [] };
  },
  computed: {
    userProfileId: function () {
      return this.posts && this.posts.length ? this.posts[0].author : 0;
    },
    canUserModify: function () {
      if (
        this.$store.state.localSettings &&
        this.$store.state.localSettings.id &&
        this.userProfileId == parseInt(this.$store.state.localSettings.id)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getSubtitle: (post) => "Created on " + Util.formatDate(post.date),
    badgeText: function (post) {
      switch (post.type) {
        case "post":
        case "note":
          return this.viewsText(post.view_count);

        case "poll":
          return this.votesText(post.poll_votes);

        default:
          return "Error";
      }
    },
    viewsText: (view_count) =>
      view_count != 1
        ? `${Util.abbreviateNumber(view_count)} Views`
        : `${view_count} View`,
    votesText: (votes) =>
      votes != 1 ? `${Util.abbreviateNumber(votes)} Votes` : `${votes} Vote`,
    deletePost(post_id) {
      this.published = this.published.filter((post) => post.id != post_id);
    },
    getPostLink: (post) => Util.getPostLink(post),
  },
  watch: {
    posts: {
      handler() {
        this.published = this.posts;
      },
      immediate: true,
    },
  },
};
</script>
