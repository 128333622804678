<template>
  <RelatedItem
    v-for="post in posts"
    :key="post"
    :post="post"
    class=""
  />
</template>

<script>
import RelatedItem from "@/templates/post-list/RelatedItem.vue";

export default {
  props: {
    posts: Array
  },
  components: {  RelatedItem },
};
</script>
