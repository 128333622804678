<template>
  <PollFeedItem :id='poll_id' :post='{}' />
</template>
<script>

//import PollFeedItem from '@/templates/poll/PollFeedItem.vue'

import { defineAsyncComponent, ref } from "vue";

const PollFeedItem = defineAsyncComponent(() =>
  import( /* webpackChunkName: "Chunks2" */ "@/templates/poll/PollFeedItem.vue" )
);


export default {
  components: { PollFeedItem },
  props: {
    id: String,
  },

  setup( props ) {

    const poll_id = ref( parseInt( props.id ) )

    return {
      poll_id
    };

  },
};
</script>
<style>
ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.poll-choices .poll-choice button,
.poll-choices .poll-choice .progressbar {
  @apply bg-dawn-pink;
}
.poll-choices .poll-choice:nth-child(2) button,
.poll-choices .poll-choice:nth-child(2) .progressbar {
  background-color: #5bb9eb;
}
.poll-choices .poll-choice:nth-child(3) button,
.poll-choices .poll-choice:nth-child(3) .progressbar {
  background-color: #fcd65c;
}
.poll-choices .poll-choice:nth-child(4) button,
.poll-choices .poll-choice:nth-child(4) .progressbar {
  background-color: #ba8fc4;
}
.poll-choices .poll-choice:nth-child(5) button,
.poll-choices .poll-choice:nth-child(5) .progressbar {
  background-color: #c1ffaf;
}
.poll-choices .poll-choice:nth-child(6) button,
.poll-choices .poll-choice:nth-child(6) .progressbar {
  background-color: #29577e;
}


</style>
