<template>
  <component :is="getComponent()" :block='block'></component>test
</template>
<script>

import { compile, h, onMounted, onUnmounted } from 'vue/dist/vue.esm-bundler.js'

import CF7 from '@/lib/ContactForm7'

import Util from '@/lib/Util'

export default{
  props: {
    html: String
  },
  setup( props ){

    const {enableCF7, disableCF7} = CF7()

    onMounted( () => {
      Util.appendScripts( props.html )
      enableCF7()
    } )

    onUnmounted( () => {
      disableCF7()
    } )

    var html = '';
    if( props.html )
      html = props.html.replace( /<script[^>]*>(?:(?!<\/script>)[^])*<\/script>/g, "" )
    html = html.replace( /<style[^>]*>(?:(?!<\/style>)[^])*<\/style>/g, "" )

    return () => h( compile( html ) )
  }
}
</script>
