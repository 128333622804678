<template>
  <div class='text-sm md:text-base md:my-2' v-if="status == 'success'">
    <Icon name='fire' class='w-4 h-4 md:w-6 md:h-6 inline text-orange font-bold' />
    {{ author.total_points }} points
  </div>
  <div class="h-1.5 bg-light-gray rounded animate-pulse" v-else></div>
</template>
<script>

import Icon from '@/components/Icons.vue';

export default {
  props: {
    author: Object,
    status: String,
  },
  components:{
    Icon
  }
};
</script>
