<template>
  <component :is="tag" @click="$emit('handleNavLink')">
    {{ linkText }}
    <slot name="icon"></slot>
  </component>
</template>

<script>
const availableTags = ["a", "router-link"];

export default {
  name: "NavLink",
  props: {
    tag: {
      type: String,
      default: "a",
      validator: (value) => availableTags.includes(value),
    },
    linkText: { type: String, required: true },
  },
};
</script>

<style></style>
