export default {
  namespaced: true,
  state: {
    showModal : false,
    post      : {},
    status    : false,
    total     : 0,
  },
  mutations: {
    showModal( state, payload ){
      state.showModal = payload;
    },
    setPost( state, payload ){
      state.post = payload;
    },
    setStatus( state, payload ){
      state.status = payload == 'open' ? true : false;
    },
    setTotal( state, payload ){
      state.total = payload;
    }
  },
};
