export default {
  namespaced: true,
  state: {
    tabs            : [],
    selectedTabIndex: 0
  },
  mutations: {
    setTabs( state, payload ) {
      state.tabs = payload;
    },
    setSelectedTabIndex( state, payload ){
      state.selectedTabIndex = payload;
    }
  },
};
