<template>

  <div class="post-list divide-y divide-lightest-gray" v-if="'success' == status">
    <ListItem
      v-for="post in posts"
      :key="post.id"
      :post="post"
      class=""
    />
  </div>

  <div class="animate-pulse" v-else>
    <div class="mb-4">
      <div class="h-6 w-6 mr-2 inline-block bg-lightest-gray rounded-full"></div>
      <div class="h-6 w-48 inline-block rounded-full">
        <div class="grid grid-cols-3 gap-4 mb-2">
          <div class="h-2 bg-lightest-gray rounded col-span-2"></div>
        </div>
        <div class="grid grid-cols-3 gap-4">
          <div class="h-2 bg-lightest-gray rounded col-span-1"></div>
          <div class="h-2 bg-lightest-gray rounded col-span-1"></div>
        </div>
      </div>
    </div>
    <div class="mb-6 flex space-x-4">
      <div class="h-16 w-16 bg-lightest-gray inline-block rounded"></div>
      <div class="h-16 w-full ">
        <div class="flex-1 space-y-4 py-1">
          <div class="h-3 bg-lightest-gray rounded"></div>
          <div class="space-y-2">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-lightest-gray rounded col-span-2"></div>
              <div class="h-2 bg-lightest-gray rounded col-span-1"></div>
            </div>
            <div class="h-2 bg-lightest-gray rounded"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-4 mb-4">
      <div class="h-2 bg-lightest-gray rounded col-span-1/2"></div>
      <div class="h-2 bg-lightest-gray rounded col-span-2"></div>
    </div>
  </div>

</template>

<script>
import ListItem from "@/templates/post-list/Item.vue";

export default {
  props: {
    posts   : Array,
    status  : String
  },
  components: {  ListItem },
};
</script>

<style scoped>
.thumbnail-bg {
  flex: 0 0 180px;
  height: 120px;
}
.post-content {
  flex-basis: calc(100% - 180px);
}

@media (max-width: 768px) {
  .thumbnail-bg {
    flex-basis: 120px;
  }

  .post-content {
    flex-basis: calc(100% - 120px);
  }
}
</style>
