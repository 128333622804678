import axios from "axios";
import store from "@/store";

axios.interceptors.request.use( ( request ) => {
  store.commit( "setProcessing", true );
  return request;
} );

axios.interceptors.response.use( ( response ) => {
  store.commit( "setProcessing", false );
  return response;
} );

const api = {

  authHeaders: function( username, password ){
    return {
      Authorization: "Basic " + btoa( username + ":" + password ),
    }
  },

  makeRequest: function ( options ) {
    var api;

    var headers = {};
    if (
      store.state.localSettings != null &&
      store.state.localSettings.username &&
      store.state.localSettings.password
    ) {

      var username = store.state.localSettings.username;
      var password = store.state.localSettings.password;

      if ( username && password ) {
        headers = this.authHeaders( username, password );
      }
    }

    var params = {};
    if (options.data) {
      params = params = this.cleanParams(options.data);
    }

    if (options.method == "post") {
      api = axios.post(options.url, options.data, { headers: headers });
    } else if (options.method == "delete") {
      //console.log( headers );
      api = axios.delete(options.url, { data: options.data, headers: headers });
    } else {
      api = axios.get(options.url, {
        params: params,
        headers: headers,
      });
    }

    return api;
  },

  _getURL: function (endpoint) {
    //console.log( endpoint );
    return process.env.VUE_APP_BASE_URL + endpoint;
  },

  cleanParams: function (params) {
    //console.log( params );

    params = JSON.parse( JSON.stringify( params ) );
    var removeAttr = [
      "pagination",
      "pagination_style",
      "style",
      "post_type",
      "method",
      "feed"
    ];
    for (var index in removeAttr) {
      var key = removeAttr[index];
      if (params[key]) {
        delete params[key];
      }
    }
    return params;
  },

  requestNewsfeed: function( post_type, params ){

    if( store?.state?.localSettings?.id ){
      
      var url = "/wp-json/yka/v2/newsfeed/" + store.state.localSettings.id + "/" + post_type + "/";

      return this.makeRequest( {
        url   : this._getURL( url ),
        method: "get",
        data  : params,
      } );
    }


  },

  requestPosts: function (post_type, params = {}) {

    var url = "/wp-json/wp/v2/" + post_type + "/";

    if ( params.trending ) {
      url = "/wp-json/yka/v2/trending/";
    }

    if( post_type == 'newsfeed' &&
      store.state.localSettings &&
      store.state.localSettings.id
    ){
      var user_id = store.state.localSettings.id;
      url = "/wp-json/yka/v2/newsfeed/" + user_id;
    }

    //console.log( this.cleanParams(params) );
    //if( params.page > 1 ) return false;
    return this.makeRequest({
      url: this._getURL(url),
      method: "get",
      data: params,
    });
  },

  requestPost: function( post_type, post_id, params = {} ){
    return this.makeRequest({
      url: this._getURL( "/wp-json/wp/v2/" + post_type + "/" + post_id ),
      method: params.method ? params.method : "get",
      data: params,
    });
  },

  createPoll: function (newPoll) {
    return this.makeRequest({
      url: this._getURL("/wp-json/yka/v2/polls"),
      data: newPoll,
      method: "post",
    });
  },

  requestPoll: function (post_id, params = {}) {

    /*
    * IF USER IS LOGGED IN
    * THEN RETRIEVE A FRESH COPY OF THE POLL EVERY TIME
    *
    if (
      store.state.localSettings != null &&
      store.state.localSettings.username &&
      store.state.localSettings.password
    ) {
      params.nowprocket = 1;
    }
    */

    return this.makeRequest({
      url: this._getURL("/wp-json/yka/v2/polls/" + post_id),
      method: params.method ? params.method : "get",
      data: params,
    });
  },

  votePoll: function (post_id, choice_id) {
    return this.makeRequest({
      url: this._getURL("/wp-json/yka/v2/polls/" + post_id + "/vote"),
      method: "post",
      data: {
        choice_id: choice_id,
      },
    });
  },

  requestUsers: function (params = {}) {
    var url = "/wp-json/yka/v1/follow/";

    if (params.trending) {
      url = "/wp-json/yka/v2/trending-authors/";
    }

    return this.makeRequest({
      url: this._getURL(url),
      data: params,
    });
  },

  requestUser: function (id, params = {}) {
    if (id) {
      return this.makeRequest({
        url: this._getURL("/wp-json/yka/v1/follow/" + id),
        data: params,
        method: params.method ? params.method : "get",
      });
    }
    console.log("user id not valid: " + id);
  },

  requestLocations: function () {
    return this.makeRequest({
      url: this._getURL("/wp-json/yka/v2/locations/"),
    });
  },

  requestSettings: function () {
    return this.makeRequest({
      url: this._getURL("/wp-json/yka/v2/settings/"),
    });
  },

  // CONTACT FORM 7 SUBMISSION
  requestCF7: function (form_id, params) {
    return this.makeRequest({
      url: this._getURL(
        "/wp-json/contact-form-7/v1/contact-forms/" + form_id + "/feedback"
      ),
      method: "post",
      data: params,
    });
  },

  createPost: function (newPost) {
    var post_type = newPost.post_type ? newPost.post_type : "posts";
    return this.makeRequest({
      url: this._getURL("/wp-json/wp/v2/" + post_type + "/"),
      data: newPost,
      method: "post",
    });
  },

  requestAttachment: function (attachment_id, params = {}) {
    if (attachment_id) {
      var url = "/wp-json/wp/v2/media/" + attachment_id;

      if (params.method == "delete") {
        url += "?force=true";
      }

      return this.makeRequest({
        url: this._getURL(url),
        data: params,
        method: params.method ? params.method : "get",
      });
    }
  },

  createAttachment: function (attachment) {
    return this.makeRequest({
      url: this._getURL("/wp-json/wp/v2/media/"),
      data: attachment,
      method: "post",
    });
  },

  requestTerm: function (term_id, taxonomy, params = {}) {
    if (term_id) {
      //console.log( params );
      return this.makeRequest({
        url: this._getURL("/wp-json/wp/v2/" + taxonomy + "/" + term_id),
        method: params.method ? params.method : "get",
        data: params,
      });
    }
    console.log("term id not valid " + term_id);
  },

  requestTerms: function (taxonomy, params = {}) {
    return this.makeRequest({
      url: this._getURL("/wp-json/wp/v2/" + taxonomy + "/"),
      method: "get",
      data: params,
    });
  },

  requestVerifyMail: function (params = {}) {
    return this.makeRequest({
      url: this._getURL("/wp-json/yka/v2/verify/"),
      method: "post",
      data: params,
    });
  },
  requestAuthenticateEmailAddress: function (params = {}) {
    return this.makeRequest({
      url: this._getURL("/wp-json/yka/v2/auth/"),
      method: "post",
      data: params,
    });
  },

  requestLogout: function (params = {}) {
    return this.makeRequest({
      url: this._getURL("/wp-json/yka/v2/logout/"),
      method: "post",
      data: params,
    });
  },


  recordAction: function( params = {} ){
    return this.makeRequest( {
      url     : this._getURL( "/wp-json/yka/v2/action" ),
      method  : "post",
      data    : params,
    } );
  },
  requestComments: function (post_id, params = {}) {
    if (post_id) {

      return this.makeRequest({
        url: this._getURL("/wp-json/wp/v2/comments/?post=" + post_id),
        method: params.method ? params.method : "get",
        data: params,
      });
    }
    console.log("Post id not valid: " + post_id);
  },
  deleteComment: function( comment_id ){
    return this.makeRequest( {
      url   : this._getURL( "/wp-json/wp/v2/comments/" + comment_id ),
      method: "delete",
    } );
  },
  createComment: function (params = {}) {

    var url = this._getURL( "/wp-json/wp/v2/comments/" );

    if( params.id )
      url += params.id;

    return this.makeRequest({
      url   : url,
      method: "post",
      data  : params,
    });
  },
  requestUserWithAuth: function (auth, params = {}) {
    const userData = params;
    if ( userData.id ) {
      return axios.post(
        this._getURL( "/wp-json/yka/v1/follow/" + userData.id ),
        userData,
        { headers: this.authHeaders( auth.username, auth.password ) }
      );
    }
    console.log( "user id not valid: " + userData.id );
  },
};

export default api;
