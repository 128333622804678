<template>
  <button
    class=""
    @click="handleClickFollowBtn"
    v-html="followFlag ? 'Following' : 'Follow'"
  ></button>
</template>
<script>

import {ref} from "vue";

import API from "@/api";
import store from "@/store";

import Util from "@/lib/Util";

export default{
  props:{
    object_type : String,
    object_id   : Number,
    follow      : Boolean
  },
  setup( props, { emit } ){

    const followFlag = ref( props.follow );

    /*
    * IF THE USER IS NOT LOGGED IN THEN OPEN THE LOGIN MODAL FIRST
    */
    const handleClickFollowBtn = () => {

      /*
      * CHECK IF USER IS LOGGED IN OR NOT
      * IF NOT LOGGED IN THEN OPEN THE LOGIN MODAL
      */
      if ( !store.state.localSettings ) {

        // CLOSE ALL MODALS
        Util.closeAllModals();

        // OPEN LOGIN MODAL
        store.commit( "login/showModal", true );
        return false;
      }

      recordFollowBtnAction();

    };

    /*
    * UPDATE THE FOLLOW REQUEST FOR THE USER
    * 1.REVERT THE FOLLOWING BOOLEAN FLAG
    * 2.UPDATE FOLLOWERS NUMBER BEFORE INCREMENTING/DECREMENTING
    * 3.SEND API REQUEST TO UPDATE THE FOLLOW OBJECT IN USER
    */
    const recordFollowBtnAction = () => {

      followFlag.value = !followFlag.value;

      API.recordAction( {
        action: followFlag.value ? "follow" : "unfollow",
        object  : props.object_type,
        id      : props.object_id,
      } ).then(
        () => emit( 'followBtnClicked', followFlag.value ),
        ( error ) => console.log( error.response.data.message )
      );


    };

    return {
      followFlag,
      handleClickFollowBtn
    }

  }
}
</script>
