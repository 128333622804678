export default {
  arrangeComments(commentsList) {
    var maxDepth = 0;
    for (let i = 0; i < commentsList.length; i++) {
      commentsList[i].commentChildren = [];
      const curretCommentDepth = this.getCommentDepth(
        commentsList[i],
        commentsList
      );
      commentsList[i].comment_depth = curretCommentDepth;
      if (curretCommentDepth > maxDepth) {
        maxDepth = curretCommentDepth;
      }
    }
    for (let j = maxDepth; j > 0; j--) {
      for (let k = 0; k < commentsList.length; k++) {
        if (commentsList[k].comment_depth == j) {
          for (let m = 0; m < commentsList.length; m++) {
            if (commentsList[k].parent == commentsList[m].id) {
              commentsList[m].commentChildren.push(commentsList[k]);
            }
          }
        }
      }
    }
    for (let n = commentsList.length - 1; n >= 0; n--) {
      if (commentsList[n].parent > 0) {
        commentsList.splice(n, 1);
      }
    }
    return commentsList;
  },
  getCommentById(commentID, commentsList) {
    for (let i = 0; i < commentsList.length; i++) {
      if (commentsList[i].id == commentID) {
        return commentsList[i];
      }
    }
  },
  getCommentDepth(comment, commentsList) {
    var depthLevel = 0;
    while (comment.parent > 0) {
      comment = this.getCommentById(comment.parent, commentsList);
      depthLevel++;
    }
    return depthLevel;
  },
};
