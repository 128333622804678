<template>
  <div
    class="mt-2 mb-4 poll-choice"
    :key="choice"
  >
    <button
      @click="setVote( choice.id )"
      class="w-full border border-lighter-gray hover:border-orange text-base text-left px-3 py-1 h-full inline-block"
    >
      {{ choice.label }}
    </button>
  </div>

</template>

<script>


export default{
  props: {
    choice: Object
  },
  methods: {
    setVote( choice_id ){
      this.$emit( 'setVote', choice_id )
    },
  },
  setup(){


  }
}
</script>
