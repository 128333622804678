<template>
  <div v-if="status == 'success'">
    <CommentList
      :comments="items"
      :key="items"
    />
    <button v-if="hasMorePages" class="outlined-button" @click="clickedOnLoadMore">Load More</button>
  </div>
  <div v-else>
    <div class="flex flex-1 items-start mb-5">
      <div
        class="bg-lighter-gray animate-pulse h-8 w-8 rounded-full mr-4"
      ></div>
      <div class="flex flex-1 flex-col">
        <div class="h-2 bg-lighter-gray rounded animate-pulse mb-2"></div>
        <div class="h-2 bg-lighter-gray rounded animate-pulse mb-2"></div>
      </div>
    </div>
  </div>
</template>

<script>

import CommentList from "@/templates/comment/List.vue";

import OrbitQuery from "@/lib/OrbitQuery";

import API from "@/api";

import {ref} from "vue";

export default{
  props:{
    post_id: Number,
    parent_id : {
      type    : Number,
      default : 0
    }
  },
  components:{
    CommentList
  },
  setup( props ){

    const params = ref( {
      per_page  : 10,
      parent    : props.parent_id,
    } );

    //console.log( params.value )

    const requestAPI = ( params ) =>
      API.requestComments( props.post_id, params );

    const {
      items,
      status,
      loadMore,
      hasMorePages,

    } = OrbitQuery( params.value, requestAPI );

    const clickedOnLoadMore = () => loadMore()


    return {
      items,
      status,
      hasMorePages,
      clickedOnLoadMore,
    }

  }

}
</script>
