import { useQuery } from "vue-query";
import API from '@/api';
import { computed } from 'vue';

const settingsModule = () => {

  const getSettings = () => API.requestSettings()

  const { data } = useQuery( "ykasettings", getSettings );

  const settings = computed( () =>
    data.value && data.value.data ? data.value.data : {}
  );

  return {
    settings
  }

}

export default settingsModule;
