export default {
  namespaced: true,
  state: {
    queryParams     : {},
    heading         : "",
    showFollowModal : false,
  },
  mutations: {
    setHeading( state, payload ) {
      state.heading = payload;
    },
    showFollowModal( state, payload ) {
      state.showFollowModal = payload;
    },
    setQueryParams( state, payload ){
      state.queryParams = payload;
    }
  },
};
