<template>
  <div
    class="max-w-xl justify-start items-start border-2 rounded-2xl border-light-gray px-5 py-8 poll-wrapper"
  >
    <div class="mb-4">
      <span class="poll-logo bg-yka-blue p-2 text-white inline-block">
        YKA <b>POLL</b>
      </span>
    </div>

    <div v-if="poll.question">
      <router-link :to="getPostLink( post )" v-if='post && post.title'>
        <div class="font-bold text-2xl mb-4" v-html='getPostTitle()'></div>
      </router-link>
      <div class="font-bold text-2xl mb-4" v-html='getPostTitle()' v-else></div>
    </div>
    <div v-else>
      <div class="h-8 animate-pulse bg-lighter-gray rounded mb-4"></div>
    </div>

    <PollChoices
      :poll='poll'
      v-if='poll && poll.options'
      @setVote='vote'
    />
    <div v-else>
      <div class="w-full animate-pulse">
        <div class="flex-1 space-y-6 py-1">
          <div class="h-2 bg-lighter-gray rounded"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-lighter-gray rounded col-span-2"></div>
              <div class="h-2 bg-lighter-gray rounded col-span-1"></div>
            </div>
            <div class="h-2 bg-lighter-gray rounded"></div>
          </div>
          <div class="h-2 bg-lighter-gray rounded"></div>
        </div>
      </div>
    </div>


    <Meta :post='post' />


  </div>
</template>
<script>
import { ref, computed, watch, defineAsyncComponent } from "vue";

import API from "@/api";
import Util from "@/lib/Util";
import store from "@/store";

import {useQuery, useQueryClient} from "vue-query";

import PollChoices from '@/templates/poll/PollChoices.vue';
//import Meta from '@/templates/poll/Meta.vue';

const Meta = defineAsyncComponent(() =>
  import( /* webpackChunkName: "Chunks2" */ "@/templates/poll/Meta.vue" )
);

export default {
  name: "PollFeedItem",
  props: {
    id: Number,
    post: Object,
  },
  components: {
    PollChoices,
    Meta
  },
  setup( props, { emit } ) {

    const isFetching = ref( false );

    const loggedInUserID = computed( () => {
      if ( store.state.localSettings && store.state.localSettings.id )
        return store.state.localSettings.id;
      return 0;
    } );

    const queryKey = [ "pollIDQuery", { id: props.id } ];

    const requestPoll = () => API.requestPoll( props.id );

    const { status, data } = useQuery( queryKey, requestPoll );

    const poll = computed( () =>
      data.value && data.value.data ? data.value.data : {}
    );

    const showProgress = computed( () =>
      data.value && data.value.data && data.value.data.has_voted ? data.value.data.has_voted : false
    );


    const totalVotes = computed( () => {
      var totalVotes = 0;
      if (poll.value.options && poll.value.options.length) {
        for (let index = 0; index < poll.value.options.length; index++) {
          totalVotes += poll.value.options[index].number;
        }
        return totalVotes;
      }
      return totalVotes;
    } );

    const queryClient = useQueryClient();

    // SET VOTE: SEND DATA TO SERVER
    const sendVoteToServer = (choice_id) => {



      isFetching.value = true;
      API.votePoll( props.id, choice_id ).then(
        (response) => {
          queryClient.setQueryData( queryKey, response );
          isFetching.value = false;
          emit( 'setVote', choice_id );
        },
        () => isFetching.value = false
      );


    };

    const vote = (choice_id) => {

      // IF NOT LOGGED IN, OPEN THE LOGIN MODAL
      if (!store.state.localSettings)
        store.commit("login/showModal", true);

      // RETURN IF ALREADY PROCESSING
      if (isFetching.value) return;


      if (!showProgress.value && store.state.localSettings)
        sendVoteToServer( choice_id );

    };

    /*
    * CHECKS IF POLL HAS BEEN LOADED
    * IF YES THEN RETURN POLL QUESTION
    * IF NOT THEN RETURNS POST TITLE
    */
    const getPostTitle = () => {

      if( poll.value && poll.value.question )
        return poll.value.question

      if( props.post && props.post.title && props.post.title.rendered )
        return props.post.title.rendered

      return ''
    }

    const getPostLink = ( post ) => Util.getPostLink( post );


    // FETCH POLL ON-MOUNT AND WHEN THE USER LOGINS
    watch(
      () => loggedInUserID.value,
      () => {
        //requestPoll();
      },
      { immediate: true }
    );


    return {
      poll,
      showProgress,
      vote,
      totalVotes,
      getPostLink,
      getPostTitle,
      status
    };
  },
};
</script>
<style>
ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.poll-choices .poll-choice button,
.poll-choices .poll-choice .progressbar {
  @apply bg-dawn-pink;
}
.poll-choices .poll-choice:nth-child(2) button,
.poll-choices .poll-choice:nth-child(2) .progressbar {
  background-color: #5bb9eb;
}
.poll-choices .poll-choice:nth-child(3) button,
.poll-choices .poll-choice:nth-child(3) .progressbar {
  background-color: #fcd65c;
}
.poll-choices .poll-choice:nth-child(4) button,
.poll-choices .poll-choice:nth-child(4) .progressbar {
  background-color: #ba8fc4;
}
.poll-choices .poll-choice:nth-child(5) button,
.poll-choices .poll-choice:nth-child(5) .progressbar {
  background-color: #c1ffaf;
}
.poll-choices .poll-choice:nth-child(6) button,
.poll-choices .poll-choice:nth-child(6) .progressbar {
  background-color: #29577e;
}
</style>
