<template>
  <router-link
    :to="getAuthorLink( author )"
    v-if='status == "success"'
    class="author-avatar"
    v-bind="$attrs"
  >
    <img :src='author.yka_avatar_url' class='rounded-full w-full h-full object-cover' />
  </router-link>
  <div v-else class='author-avatar animate-pulse h-12 w-12' v-bind="$attrs"></div>
</template>
<script>
import Util from '@/lib/Util'

export default{
  props: {
    author: Object,
    status: String
  },
  methods: {
    getAuthorLink: ( author ) => Util.getAuthorLink( author )
  }
}
</script>

<style scoped>
  .author-avatar{
    @apply rounded-full flex items-center justify-center text-3xl bg-light-gray;
    @apply overflow-hidden border border-light-gray;
  }
</style>
