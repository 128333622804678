import { createStore } from "vuex";
import commentModule from "../components/comment/store";
import userFollow from "@/store/userFollow";
import archiveTabs from "@/store/archiveTabs";
import comments from "@/store/comments";
import login from "@/store/login";
import pollSlides from "@/store/pollSlides";

export default createStore( {
  state: {
    localSettings: null,
    settings: {
      base_url: "",
    },
    post: {},
    author: {},
    processing: false,
    errors: [],
    status: "loading",
  },
  mutations: {
    getLocalSettings( state ) {
      if ( localStorage.yka_settings ) {
        state.localSettings = JSON.parse( localStorage.yka_settings );
      }
      return state.localSettings;
    },
    saveLocalSettings( state, payload ) {
      state.localSettings = payload;
      localStorage.yka_settings = JSON.stringify( payload );
    },

    setProcessing( state, flag ) {
      state.processing = flag;
      return state.processing;
    },
    notifyError( state, errorText ) {
      state.errors.push( errorText );
      return state.errors;
    },
    flushError( state, index ) {
      state.errors.splice( index, 1 );
      return state.errors;
    },
    setSettings( state ) {
      if ( window.ykasettings ) {
        state.settings = window.ykasettings;
        //console.log( window.ykasettings );
      }
      return state.settings;
    },
    setAuthor(state, payload) {
      state.author = payload;
      return state.author;
    },
    setPost(state, payload) {
      state.post = payload;
      return state.post;
    },
    setStatus(state, payload) {
      state.status = payload;
      return state.status;
    },

    flushLocalSettings(state) {
      state.localSettings = null;
      localStorage.yka_settings = null;
    },
  },
  modules: {
    commentModule,
    userFollow,
    archiveTabs,
    comments,
    login,
    pollSlides
  },
} );
