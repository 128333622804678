<template>
  <div
    class="right-sidebar lg:sticky lg:top-0 lg:px-8 p-4 pb-10 lg:pb-0 lg:min-h-screen"
  >
    <div class="h-0 lg:my-10 text-sm invisible lg:visible lg:h-auto">
      <ul class="mb-10" v-if="!$store.state.localSettings">
        <li class="inline-block mr-4 w-7/12">
          <a
            class="p-2 px-4 rounded-full w-full bg-yka-blue inline-block text-white w-full text-center"
            href="#login"
            data-behaviour="login-redirect-modal"
            >Participate</a
          >
        </li>
        <li class="inline-block text-right text-yka-blue">
          <a href="#login" data-behaviour="login-modal">Sign In</a>
        </li>
      </ul>
    </div>

    <RenderHtml
      v-if="settings.sidebars"
      :html="getSidebarContent('primary-right')"
    />

    <div class="my-8"></div>

    <div v-if="settings.version">
      <h4 class="mb-4 font-bold">Recommended Topics</h4>
      <OrbitTerms
        :params="{ orderby: 'count', order: 'desc', exclude: excludeCategories() }"
      />
    </div>


    <div class="my-20"></div>
    <FooterMenu class="mb-2 mt-4 lg:mb-0 lg:absolute lg:bottom-5" />
    <div class="opacity-0">END</div>
  </div>
</template>
<script>

import RenderHtml from "@/lib/RenderHtml.vue";
import OrbitTerms from "@/lib/OrbitTerms.vue";
//import OrbitAuthor from "@/lib/OrbitAuthor.vue";
import FooterMenu from "@/components/footer/FooterMenu.vue";

import Settings from "@/lib/Settings";

export default {
  components: {
    OrbitTerms,
    //OrbitAuthor,
    FooterMenu,
    RenderHtml,
  },
  methods: {
    checkForSinglePost() {

      const allowedRoutes = ["SinglePost", "SingleVideo", "SinglePoll"];

      return (
        this.$store.state.post &&
        this.$store.state.post.author &&
        allowedRoutes.includes(this.$route.name)
      );
    },


  },
  setup(){

    const {settings} = Settings()

    const getSidebarContent = ( sidebarId ) => settings.value.sidebars[ sidebarId ]

    const excludeCategories = () =>
      [ settings.value.exclude_categories.unlisted, settings.value.exclude_categories.unreviewed ]

    return {
      settings,
      getSidebarContent,
      excludeCategories
    }

  }
};
</script>
