<template>
  <div class="shadow-lg topbar py-3">
    <div class="page-container flex justify-between items-center">
      <div class="site-logo flex items-center">
        <router-link
          :to="homeURL()"
          class="block">
          <img
            width="200"
            height="123"
            class="w-20 md:w-28"
            :src="logoURL()"
            alt="YKA"
          />
        </router-link>

        <span
          v-if="$route.name == 'FEP' && $store.state.localSettings"
          class="logo-title hidden sm:block font-thin ml-4 text-base text-gray text-left text-xs"
        >
          Draft in
          <b class="font-semibold">{{ $store.state.localSettings.name }}</b>
        </span>
      </div>
      <div class="topbar-buttons" role="navigation">
        <ul class="flex items-center">
          <li class="search-icon">
            <router-link to="/search/" class="block">
              <Icon class="h-6 w-6 text-yka-blue" name="search" />
            </router-link>
          </li>
          <li class="btn-hindi" v-if="!$store.state.localSettings">
            <a
              href="#login"
              data-behaviour="login-redirect-newsfeed"
              class="button bg-yka-blue text-white"
              >Participate</a
            >
          </li>
          <template v-if="$store.state.localSettings">
            <li class="btn-hindi">
              <router-link
                to="/me"
                class="button text-yka-blue"
                >Create</router-link
              >
            </li>
          </template>

          <li class="hamburger" v-if="!$store.state.localSettings">
            <SideMenu />
          </li>
          <li>
            <UserMenu />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icons.vue";
import SideMenu from "@/components/header/SideMenu.vue";
import UserMenu from "@/components/header/UserMenu.vue";

import store from "@/store";

export default {
  components: {
    SideMenu,
    UserMenu,
    Icon,
  },

  setup(){

    const logoURL = () => process.env.VUE_APP_LOGO_URL;

    const homeURL = () =>
      store.state?.localSettings?.id ? { name: 'Newsfeed' } : { name : 'Home' };

    return {
      homeURL,
      logoURL
    }

  }
};
</script>

<style scoped>
.topbar-buttons .button {
  @apply block text-sm border-2 border-yka-blue rounded-full py-1 px-4;
}

.topbar-buttons li {
  @apply ml-2 md:ml-3;
}

@media (max-width: 375px) {
  .topbar-buttons .hide-btn-hindi {
    display: none;
  }
}
</style>
