<template>
  <div class="relative">
    <div
      class="absolute right-0 font-semibold p-2 flex justify-center items-center poll-points"
    >
      You've earned 2 points
    </div>
  </div>
</template>
<script>
export default {
  name: "PollRewards",
};
</script>
<style>
@keyframes points {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.poll-points {
  @apply opacity-0;
  -webkit-animation: points 0.8s 1 forwards;
  animation: points 0.8s 1 forwards;
}
</style>
