<template>
  <div class="flex flex-wrap flex-row items-stretch two-grid -mx-2">
    <div class="flex p-2 w-full md:w-1/2" v-for="post in posts" :key="post">
      <div class="border border-lighter-gray w-full rounded">
        <router-link
          v-if="hasFeaturedImage(post)"
          :to="getPostLink(post)"
          class="thumbnail-bg h-32 bg-gray block"
        >
          <PostFeaturedImage
            :media_id="post.featured_media"
            :key="post.featured_media"
          />
        </router-link>
        <div class="post-content">
          <router-link :to="getPostLink(post)">
            <h5
              class="font-bold text line-clamp-3"
              v-html="post.title.rendered"
            ></h5>
            <div
              class="text-gray line-clamp-2"
              v-html="post.excerpt.rendered"
            ></div>
          </router-link>
        </div>
        <div class="p-4">
          <!--div class="mb-4 flex flex-wrap gap-1 text-xs md:text-sm">
            <OrbitAuthor
              class="mr-1"
              :params="{ style: 'AuthorAvatar', id: post.author }"
              v-if="post.author"
            />

            <OrbitAuthor
              class="mt-1"
              :params="{ style: 'AuthorName', id: post.author }"
              v-if="post.author"
            />
            <div class="mt-1" v-if="getFirstCategory( post )">
              <span class="mr-1">in</span>
              <OrbitTerm
                class="underline"
                :params="{ style: 'TermName', id: getFirstCategory(post) }"
              />
            </div>

          </div-->
          <AuthorAvatarBox status="success" :post="post">
            <template v-slot:metacategories>
              <OrbitTerm
                :params="{ style: 'TermName', id: getFirstCategory(post) }"
              />
            </template>
          </AuthorAvatarBox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Util from "@/lib/Util";
import OrbitTerm from "@/lib/OrbitTerm.vue";
import PostFeaturedImage from "@/templates/post/FeaturedImage.vue";
import AuthorAvatarBox from "@/components/post/AuthorAvatarBox.vue";
//import OrbitAuthor from "@/lib/OrbitAuthor.vue";

export default {
  props: {
    posts: Array,
    status: String,
  },
  components: {
    //OrbitAuthor,
    AuthorAvatarBox,
    OrbitTerm,
    PostFeaturedImage,
  },
  methods: {
    truncate: (text) => Util.truncate(text),
    getPostLink: (post) => Util.getPostLink(post),
    getFirstCategory: (post) =>
      post.categories && post.categories.length ? post.categories[0] : 0,
    getFeaturedImage: (post) =>
      post.featured_image ? post.featured_image : "",
    hasFeaturedImage: (post) => Util.hasFeaturedImage(post),
  },
};
</script>
<style>
.two-grid .thumbnail-bg {
  height: 200px;
}

.two-grid .thumbnail-bg > img {
  border: none;
}

.two-grid .post-content {
  @apply p-4;
  min-height: 120px;
}

.two-grid .author-avatar-parent {
  @apply hidden;
}

.two-grid .author-name {
  @apply font-normal;
}
</style>
