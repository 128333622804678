<template>
  <article class="trending">
    <div class="index">
      {{ getPostIndex() }}
    </div>
    <div class="w-full flex flex-col justify-between items-start">
      <div class="title">
        <router-link
          :to="getPostLink(post)"
          v-html="truncate(post.title.rendered)"
        ></router-link>
      </div>
      <AuthorAvatarBox status="success" :post="post">
        <template v-slot:metacategories>
          <OrbitTerm
            :params="{ style: 'TermName', id: getFirstCategory(post) }"
          />
        </template>
      </AuthorAvatarBox>
    </div>
  </article>
</template>

<script>
import Util from "@/lib/Util";
import OrbitTerm from "@/lib/OrbitTerm.vue";
import AuthorAvatarBox from "@/components/post/AuthorAvatarBox.vue";

export default {
  name: "TrendingCard",
  components: {
    AuthorAvatarBox,
    OrbitTerm,
  },
  props: {
    post: Object,
    postIndex: Number,
  },
  methods: {
    getPostIndex() {
      const index = this.postIndex + 1;
      return index > 9 ? index : `0${index}`;
    },
    truncate: (text) => Util.truncate(text),
    getPostLink: (post) => Util.getPostLink(post),
    getFeaturedImage: (post) =>
      post.featured_image ? post.featured_image : "",
    formatDate: (dateString) => Util.formatDate(dateString),
    getFirstCategory: (post) =>
      post.categories && post.categories.length ? post.categories[0] : 0,
  },
};
</script>
