<template>
  <div class="post-list post-list-two-grid lg:-mx-4 lg:flex flex-wrap">
    <ListItem
      v-for="post in posts"
      :key="post.id"
      :post="post"
      class="lg:w-1/2 lg:p-4"
    />
  </div>
</template>

<script>
//import { posts } from "@/data.js";
import ListItem from "@/templates/post-list/ListItem.vue";

export default {
  components: { ListItem },
  props: {
    posts: Array,
  },
};
</script>
