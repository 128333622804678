<template>
  <div class="max-w-full overflow-hidden">
    <div class="trending-posts">
      <TrendingItem
        v-for="(post, index) in posts"
        :key="post.id"
        :post="post"
        :postIndex="index"
      />
    </div>
  </div>
</template>

<script>
import TrendingItem from "@/templates/post-list/TrendingItem.vue";

export default {
  props: {
    posts: Array,
  },
  components: { TrendingItem },
};
</script>

<style>
.trending-posts {
  @apply flex flex-wrap lg:-mx-10 md:-mx-6;
}
.trending-posts article.trending {
  @apply w-full lg:w-1/3 md:w-1/2 mb-10 flex lg:px-10 md:px-6;
}
.trending-posts .index {
  @apply text-yka-blue text-center text-4xl w-10 relative mr-4 opacity-50;
}
.trending-posts .author-name {
  @apply font-normal;
}
.trending-posts .title {
  @apply text-base text-lg font-bold text-black tracking-tight leading-tight mb-4;
}

article.trending .author-avatar {
  @apply h-8 w-8;
}
article.trending .author-avatar-parent {
  @apply mr-2;
}
article.trending .meta .item {
  @apply mr-1;
}

.right-sidebar .trending-posts {
  @apply flex-none;
}
.right-sidebar .trending-posts .trending {
  @apply w-full mb-0;
}
.right-sidebar .trending-posts .index {
  @apply mr-2;
}
.right-sidebar .trending-posts .trending .author-avatar-parent {
  @apply hidden;
}
.right-sidebar .trending-posts .author-name {
  @apply text-xs text-mid-gray;
}
.right-sidebar .trending-posts .meta {
  @apply text-mid-gray;
}
.right-sidebar .trending-posts .title {
  @apply mb-0 text-sm font-medium;
}
</style>
