<template>
  <ul class="footer-social-icons">
    <li v-for="icon in icons" :key="icon.name">
      <a :href="icon.href" target="_blank" :title="icon.title">
        <Icons :name="icon.name" class="h-5 w-5" />
      </a>
    </li>
  </ul>
</template>

<script>
import Icons from "@/components/Icons.vue";

export default {
  components: {
    Icons,
  },
  data() {
    return {
      icons: [
        {
          name: "facebook",
          title: "Youth Ki Awaaz on Facebook",
          href: "https://www.facebook.com/youthkiawaaz",
        },
        {
          name: "twitter",
          title: "Youth Ki Awaaz on Twitter",
          href: "https://twitter.com/YouthKiAwaaz",
        },
        {
          name: "instagram",
          title: "Youth Ki Awaaz on Instagram",
          href: "https://instagram.com/youthkiawaaz",
        },
        {
          name: "youtube",
          title: "Youth Ki Awaaz on Youtube",
          href: "https://www.youtube.com/youthkiawaaz",
        },
        {
          name: "envelope",
          title: "Contact Youth Ki Awaaz",
          href: "https://www.youthkiawaaz.com/contact-us/",
        },
        {
          name: "whatsapp",
          title: "Whatsapp Youth Ki Awaaz",
          href: "https://yka.io/polling-community",
        },
      ],
    };
  },
};
</script>
