<template>
  <div class="user-menu" v-if="$store.state.localSettings">
    <div @click="toggleDropdownMenu()" class="user-profile-pic">
      <img :src="$store.state.localSettings.avatar_url" />
    </div>
    <div class="user-submenu" v-if="openProfileDropdown">
      <ul>
        <li @click="goToProfile" class="cursor-pointer menu-item">
          <div class="flex">
            <div class="h-6 w-6 overflow-hidden bg-lightest-gray rounded-full inline mr-2">
              <img
                class="object-cover w-full h-full"
                :src="$store.state.localSettings.avatar_url"
              />
            </div>
            <div class="truncate">{{ $store.state.localSettings.name }}</div>
          </div>
        </li>
        <li class="menu-item">
          <template v-for="button in buttons" :key="button.title">
            <p class="writing-menu-item">
              <router-link
                :to="button.link"
                @click="openProfileDropdown = false"
              >
                <Icon :name="button.icon" />{{ button.title }}
              </router-link>
            </p>
          </template>
          <p class="edit-menu-item">
            <router-link to="/edit" @click="openProfileDropdown = false">
              <Icon name="edit-profile" />Edit Profile
            </router-link>
          </p>
        </li>
        <li class="menu-item menu-item-last">
          <p class="edit-menu-item">
            <router-link to="/logout" @click="openProfileDropdown = false">
              <Icon name="logout" />Logout
            </router-link>
          </p>
          <FooterMenu
            :key="openProfileDropdown"
            class="bg-lightest-gray -mx-4 p-4 text-xs"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Util from "@/lib/Util";
import Icon from "@/components/Icons.vue";

import FooterMenu from "@/components/footer/FooterMenu.vue";

export default {
  components: {
    Icon,
    FooterMenu
  },
  data() {
    return {
      openProfileDropdown: false,
      buttons: [
        /*
        {
          title: "Newsfeed",
          link: "/newsfeed",
          icon: "stories",
        },
        */
        {
          title: "Create Poll",
          link: "/create-poll",
          icon: "polls",
        },
        {
          title: "Start Writing",
          link: "/me",
          icon: "writing",
        },
        {
          title: "My State Feed",
          link: "/mystatefeed",
          icon: "newspaper",
        },
      ],
    };
  },
  methods: {
    toggleDropdownMenu() {
      this.openProfileDropdown = !this.openProfileDropdown;
      if (this.openProfileDropdown) {
        document.addEventListener("click", this.closeWhenClickedOutside);
      }
    },
    closeWhenClickedOutside(event) {
      if (!event.target.closest(".user-menu")) {
        this.openProfileDropdown = false;
        document.removeEventListener("click", this.closeWhenClickedOutside);
      }
    },
    goToProfile(ev) {
      ev.preventDefault();
      var route = Util.getProfileLink();
      this.openProfileDropdown = false;
      this.$router.push(route);
    },
  },
};
</script>
