<template>
  <div class="sticky-menu border-lighter-gray">
    <router-link :to="homeURL()" class="block invisible lg:visible">
      <img alt="YKA Logo" :src="logoURL()" class="w-0 lg:w-16 lg:mb-20" />
    </router-link>
    <ul class="sticky-icons">
      <li class="sticky-menu-item">
        <router-link :to="homeURL()">
          <Icon name="home" />
        </router-link>
      </li>
      <li class="sticky-menu-item">
        <router-link to="/search">
          <Icon name="search" />
        </router-link>
      </li>
      <li class="sticky-menu-item">
        <router-link to="/me">
          <Icon name="writing" />
        </router-link>
      </li>
      <li class="sticky-menu-item">
        <router-link to="/create-poll">
          <Icon name="polls" />
        </router-link>
      </li>
      <li class="user-menu-container"><UserMenu /></li>
      <!--li class="side-menu-container"><SideMenu /></li-->
    </ul>
  </div>
</template>
<script>
import Icon from "@/components/Icons.vue";
//import SideMenu from "@/components/header/SideMenu.vue";
import UserMenu from "@/components/header/UserMenu.vue";

import store from "@/store";

export default {
  components: {
    //SideMenu,
    UserMenu,
    Icon,
  },
  methods: {
    /*
    currentUserID() {
      return this.$store.state.localSettings &&
        this.$store.state.localSettings.id
        ? this.$store.state.localSettings.id
        : 0;
    },
    openCommonWriting(ev) {
      ev.preventDefault();
      this.$router.push( "/me" );
    },
    */
  },
  setup(){

    const logoURL = () => process.env.VUE_APP_LOGO_URL;

    const homeURL = () =>
      store.state?.localSettings?.id ? { name: 'Newsfeed' } : { name : 'Home' };

    return{
      homeURL,
      logoURL
    }
  }
};
</script>
