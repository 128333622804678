<template>
  <div class="" v-if="posts.length">
    <article
      v-for="(post, i) in polls"
      :key="post"
      class="w-full"
      :class="!i ? 'inline-block' : 'hidden'"
    >
      <PollRewards v-if="pointsAnimationEnabled" />
      <PollFeedItem
        @setVote="vote(post.id)"
        :id="post.poll_id"
        v-if="post.poll_id"
      />
      <button
        v-if="timerEnabled"
        class="outlined-button mt-4 text-xs relative z-10"
        @click="moveToNextPoll(post.id)"
        v-html="skipHtml"
      ></button>
    </article>
  </div>
</template>

<script>
import PollFeedItem from "@/templates/poll/PollFeedItem.vue";

import { ref, watch, computed, onMounted } from "vue";

import { useQueryClient } from "vue-query";

import store from "@/store";
import PollRewards from "./PollRewards.vue";

export default {
  props: {
    posts: Array,
    status: String,
  },
  components: {
    PollFeedItem,
    PollRewards,
  },

  setup(props) {
    const queryClient = useQueryClient();

    const polls = ref([]);

    const timerCount = ref(5);

    const timerEnabled = ref(false);

    const pointsAnimationEnabled = ref(false);

    const closeModal = () => {
      store.commit("pollSlides/showModal", false);
      store.commit("pollSlides/setParams", {});
      queryClient.refetchQueries();
    };

    const moveToNextPoll = (post_id) => {
      resetTimer();

      // REMOVE THE POST POLL THAT WAS VOTED
      polls.value = polls.value.filter((poll) => poll.id != post_id);

      // CLOSE THE MODAL
      if (!polls.value.length) {
        closeModal();
      }
    };

    const skipHtml = computed(() => {
      var text = polls.value.length > 1 ? "Skipping" : "Closing";
      return text + " in " + timerCount.value + " seconds";
    });

    const vote = (post_id) => {
      timerEnabled.value = true;
      pointsAnimationEnabled.value = true;

      playTimer();

      setTimeout(() => {
        moveToNextPoll(post_id);
      }, 4000);
    };

    const resetTimer = () => {
      timerEnabled.value = false;
      timerCount.value = 5;
      pointsAnimationEnabled.value = false;
    };

    const reduceTimer = () => {
      timerCount.value--;
      if (timerCount.value > 0) setTimeout(() => playTimer(), 1000);
      else resetTimer();
    };

    const playTimer = () => {
      if (timerEnabled.value) reduceTimer();
    };

    watch(
      () => props.posts,
      (newPosts) => (polls.value = newPosts),
      { immediate: true }
    );

    onMounted(() => {
      resetTimer();
    });

    return {
      moveToNextPoll,
      vote,
      polls,
      skipHtml,
      timerCount,
      timerEnabled,
      pointsAnimationEnabled,
    };
  },
};
</script>
