<template>
  <footer>
    <div class="page-container lg:flex justify-between items-center text-gray">
      <div class="footer-social">
        <div class="yka-copyright relative inline-block">
          © YKA Media Pvt. Ltd.
        </div>
        <FooterIcons />
      </div>
      <FooterMenu class="w-full lg:w-1/2" />
    </div>
  </footer>
</template>

<script>
import FooterMenu from "@/components/footer/FooterMenu.vue";
import FooterIcons from "@/components/footer/FooterIcons.vue";

export default {
  components: {
    FooterMenu,
    FooterIcons,
  },
};
</script>
