<template>
  <button class="block" @click="toggleSidebarMenu">
    <Icon name="hamburger" class="h-7 w-7 text-yka-blue font-bold" />
  </button>
  <nav
    class="sidebar-collapse h-full w-full top-0 fixed left-0"
    :class="{ open: showSidebarMenu }"
  >
    <div class="absolute bg-white top-0 left-0 w-full h-full opacity-95"></div>
    <div class="absolute w-full h-full mx-auto px-0 py-20 sm:p-20">
      <button
        class="block absolute right-10 top-10 z-20"
        @click="toggleSidebarMenu"
      >
        <Icon name="close" class="h-7 w-7" />
      </button>


      <ul class="menu flex flex-col items-center" v-if='settings.menus && settings.menus.primary'>
        <li v-for="(item, key) in settings.menus.primary" :key="key">
          <NavLink
            v-if="item.tag == 'a'"
            :href="item.path"
            target="_blank"
            :linkText="item.title"
            :tag="item.tag"
            @handleNavLink="handleClick"
          >
            <template v-slot:icon>
              <span aria-hidden="true">
                <Icon
                  name="new-tab"
                  class="text-yka-blue font-bold inline-block"
                />
              </span>
            </template>
          </NavLink>
          <NavLink
            v-if="item.tag == 'router-link'"
            :to="item.path"
            :linkText="item.title"
            :tag="item.tag"
            @handleNavLink="handleClick"
          />
        </li>
      </ul>
      <FooterIcons />
    </div>
  </nav>
</template>

<script>
import Icon from "@/components/Icons.vue";
import NavLink from "@/components/NavLink.vue";
import FooterIcons from "@/components/footer/FooterIcons.vue";

import Settings from '@/lib/Settings';

import { ref } from 'vue';

export default {
  components: {
    Icon,
    NavLink,
    FooterIcons,
  },
  setup(){

    const showSidebarMenu = ref( false );

    const {settings} = Settings()

    const toggleSidebarMenu = () => showSidebarMenu.value = !showSidebarMenu.value;

    const handleClick = () => showSidebarMenu.value = false;

    return {
      settings,
      showSidebarMenu,
      toggleSidebarMenu,
      handleClick
    }

  }
};
</script>

<style scoped>
.sidebar-collapse {
  @apply z-50;
  transform: translateX(-100%);
  transition: all ease 0.25s;
}

.open.sidebar-collapse {
  transform: translateX(0);
}
nav ul.menu li {
  @apply mb-3;
}
nav ul.menu a[href] {
  @apply block text-yka-blue text-lg font-bold py-2 px-4;
}
</style>
