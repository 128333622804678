<template>
  <div class="lg:-mx-2 lg:flex flex-wrap">
    <RelatedItem
      v-for="post in posts"
      :key="post"
      :post="post"
      class="lg:w-1/2 px-2 py-2"
    />
  </div>
</template>

<script>
import RelatedItem from "@/templates/post-list/RelatedItem.vue";

export default {
  props: {
    posts: Array
  },
  components: {  RelatedItem },
};
</script>
