<template>
  <div class="card-item">
    <h2
      v-if="title"
      v-html="title"
      class="font-medium text-left my-2 text-black text-2xl lg:text-2xl"
    ></h2>
    <slot name="cardheader"></slot>
    <p
      class="font-medium text-mid-gray text-base lg:text-md"
      v-html="subtitle"
    ></p>
    <div class="badge">
      <span v-html="badge"></span>
    </div>
    <slot name="cardcontent"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    badge: String,
  },
};
</script>

<style scoped>
.card-item {
  box-shadow: 0 0 10px rgb(27 27 27 / 20%);
  @apply relative p-4 lg:p-6 rounded-lg mb-10;
}
.card-item .badge {
  top: 0;
  transform: translateY(-50%);
  @apply absolute -right-1 lg:-right-5;
}
.card-item .badge span {
  border: 2.5px solid #5ecbcf;
  @apply inline-block text-yka-blue bg-white text-xs font-bold py-2 px-5 rounded-3xl;
}
</style>
