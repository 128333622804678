<template>
  <div>
    <div class="text-xs text-light-gray mb-2" v-if="'ChoicePreLogin' != style">
      <Icon name="check-circle" class="w-4 h-4 inline" />
      Select one
    </div>

    <div class='poll-choices'>

      <component
        :is="style"
        :choice="choice"
        v-for="choice in poll.options"
        :key="choice"
        :percentage='getPercentage( choice.number )'
        @setVote='setVote'
      ></component>

    </div>

    <div class='mt-8' v-if="'ChoicePreLogin' == style">
      <a
        href="#login"
        data-behaviour="login-modal"
        class="button border-yka-blue text-yka-blue text-sm text-center p-2 px-6 border rounded-full font-bold"
        >
        Login to vote
      </a>
    </div>

  </div>
</template>



<script>

import { computed } from 'vue'

import store from "@/store";

import ChoicePreLogin from '@/templates/poll/ChoicePreLogin.vue'
import ChoicePostLogin from '@/templates/poll/ChoicePostLogin.vue'
import ChoicePostVote from '@/templates/poll/ChoicePostVote.vue'
import Icon from "@/components/Icons.vue";

export default{
  props: {
    poll: Object
  },
  components:{
    Icon,
    ChoicePreLogin,
    ChoicePostLogin,
    ChoicePostVote
  },
  setup( props, { emit } ){


    /*
    * GET USER ID OF THE CURRENTLY LOGGED IN USER
    */
    const loggedInUserID = computed( () => {
      if (store.state.localSettings && store.state.localSettings.id)
        return store.state.localSettings.id;
      return 0;
    } )

    const style = computed( () => {

      if( loggedInUserID.value && props.poll && props.poll.has_voted )
        return 'ChoicePostVote'

      if( loggedInUserID.value )
        return 'ChoicePostLogin'

      return 'ChoicePreLogin';
    } )

    /*
    * GET THE TOTAL NUMBER OF VOTES FOR THE POLL
    */
    const totalVotes = computed( () => {
      var totalVotes = 0;
      if ( props.poll && props.poll.options && props.poll.options.length ) {
        for ( let index = 0; index < props.poll.options.length; index++ ) {
          totalVotes += props.poll.options[ index ].number;
        }
      }
      return totalVotes;
    } );

    /*
    * CONVERT THE NUMBER OF VOTES TO PERCENTAGE
    */
    const getPercentage = ( votes ) => {
      var percentage = 0;
      if ( parseInt( votes ) && totalVotes.value ) {
        percentage = Math.floor( (100 * votes) / totalVotes.value );
      }
      return percentage;
    }

    const setVote = ( choice_id ) => emit( 'setVote', choice_id )

    return{
      style,
      getPercentage,
      setVote
    }

  }

}

</script>
