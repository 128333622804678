<template>
  <div>
    <div class="video-grid lg:flex lg:flex-wrap lg:-mx-3">
      <article
        class="w-full h-full mb-6 lg:m-0 lg:w-1/3 lg:p-3"
        v-for="post in posts"
        :key="post.id"
        :post="post"
      >
        <div
          class="relative bg-no-repeat bg-cover bg-center h-80 min-h-full"
          :style="{ backgroundImage: `url(${post.featured_image})` }"
        >
          <div class="video-overlay absolute w-full h-full">
            <span class="play-btn"></span>
            <div
              class="text-white h-24 w-3/4 absolute left-3 bottom-1 font-bold overflow-hidden"
              v-html="post.title.rendered"
            ></div>
            <router-link
              :to="getPostLink(post)"
              class="absolute w-full h-full"
            ></router-link>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import DefaultMixin from "@/mixins/DefaultMixin.js";
import Util from "@/lib/Util.js";

export default {
  mixins: [DefaultMixin],
  props: {
    posts: Array,
  },
  methods: {
    getPostLink: (post) => Util.getPostLink(post),
  },
};
</script>

<style scoped>
.video-grid .video-grid-title a {
  background: #e1e5e8;
}
.video-grid .video-overlay {
  background: rgba(0, 0, 0, 0.6);
}
.video-grid .play-btn,
.video-grid .play-btn::after {
  @apply text-white absolute top-1/2 left-1/2 w-10 h-10 border-2 border-white rounded-full;
  transform: translate(-50%, -50%);
}
.video-grid .play-btn::after {
  content: "";
  @apply w-0 h-0 rounded-none;
  border: 8px solid transparent;
  border-left: 15px solid #fff;
  margin-left: 6px;
}
</style>
