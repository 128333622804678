<template>
  <ul id="comment-list" class="divide-y divide-lightest-gray mt-6">
    <Comment
      v-for="comment in items"
      :key="comment.id"
      :comment="comment"
      @deleteComment="deleteComment"
    />
  </ul>
</template>

<script>
import Comment from "@/templates/comment/item.vue";

import { ref } from "vue";

export default {
  props: {
    comments: Array,
  },
  components: {
    Comment,
  },
  setup(props) {
    const items = ref(props.comments);

    const deleteComment = (comment_id) =>
      (items.value = items.value.filter((item) => item.id != comment_id));

    return {
      items,
      deleteComment,
    };
  },
};
</script>
