<template>
  
  <div class="w-full mt-2 mb-4 poll-choice">
    <div class="border border-lighter-gray relative">
      <div
        role="progressbar"
        class="h-full progressbar absolute"
        :aria-valuenow="percentage"
        :style="{ width: `${percentage}%` }"
      ></div>
      <div class="h-full text-base px-3 py-1 relative">
        <div class="flex items-center gap-3">
          <span class="w-full">{{ choice.label }}</span>
          <span class="inline-block text-xs font-semibold text-black"
            >{{ percentage }}%</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default{
  props:{
    choice: Object,
    percentage: Number
  },

}

</script>
