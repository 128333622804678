<template>
  <ul class="">
    <li
      class="inline-block bg-lightest-gray mr-4 p-2 px-4 rounded-full text-sm mb-4"
      v-for="item in items"
      :key="item"
    >
      <router-link :to="getLink(item)">
        {{ item.name }}
      </router-link>
    </li>
  </ul>
  <slot name="whenempty" v-if="!items.length"></slot>
</template>

<script>
import API from "@/api";
import Util from "@/lib/Util";
import OrbitQuery from "@/lib/OrbitQuery";

export default {
  props: {
    params: Object,
  },
  setup(props) {
    const requestAPI = (params) => API.requestTerms("categories", params);

    const { items } = OrbitQuery(props.params, requestAPI);

    const getLink = (term) => Util.getTermLink(term);

    return {
      items,
      getLink,
    };
  },
};
</script>
