<template>

  <div class='author-info flex flex-1 flex-wrap gap-4 md:gap-8'>
    <div class='author-avatar-parent flex-col'>
      <AuthorAvatar class="author-avatar w-20 h-20 md:w-32 md:h-32" :author="author" :status="status" />
    </div>
    <div class='flex flex-col w-8/12'>
      <AuthorName class="author-name text-xl md:text-2xl font-bold" :author="author" :status="status" />
      <AuthorPoints class="author-points" :author="author" :status="status" />

      <div class='author-buttons mt-2 grid grid-cols-2 gap-2 max-w-xs'>

        <FollowBtn
          v-if="!isCurrentUser() && author.follow"
          class="outlined-button"
          :follow="author.follow.is_following"
          object_type="user"
          :object_id="author.id"
          @followBtnClicked="followBtnClicked"
        />


        <button
          v-if="!isCurrentUser()"
          @click="handleClickBtn"
          class="outlined-button"
          v-html="blockBtnText()"
        ></button>
        <router-link
          v-if="isCurrentUser()"
          class="outlined-button"
          :to="{ name: 'EditProfile' }"
          >Edit</router-link
        >
      </div>
    </div>

  </div>



</template>

<script>
import AuthorName from "@/templates/author/Name.vue";
import AuthorAvatar from "@/templates/author/Avatar.vue";
import AuthorPoints from "@/templates/author/Points.vue";

import FollowBtn from "@/lib/FollowBtn.vue";

import API from "@/api";
import Util from "@/lib/Util";
import store from '@/store';

import { ref } from 'vue';

export default {
  props: {
    author: Object,
    status: String,
  },
  components: {
    AuthorAvatar,
    AuthorName,
    AuthorPoints,
    FollowBtn
  },
  watch: {
    author: function () {
      this.$store.commit( "userFollow/showFollowModal", false );
    },
  },
  beforeUnmount() {
    this.$store.commit( "userFollow/showFollowModal", false );
  },
  setup( props, { emit } ){

    const cloneAuthor = ref( Util.convertToObject( props.author ) );


    const followBtnText = () =>
      cloneAuthor.value.follow && cloneAuthor.value.follow.is_following ?
      "Following" : "Follow";

    const blockBtnText = () =>
      cloneAuthor.value.block && cloneAuthor.value.block.is_blocked ?
      "Unblock" : "Block";


    /*
    * GET CURRENT USER ID
    */
    const getCurrentUserID = () =>
      store.state.localSettings && store.state.localSettings.id ?
      store.state.localSettings.id : 0;

    const handleClickBtn = ( ev ) => {
      ev.preventDefault();

      // IF THE USER IS NOT LOGGED IN THEN OPEN THE LOGIN MODAL FIRST
      if ( !store.state.localSettings ) {
        store.commit( "login/showModal", true );
        return false;
      }

      Util.debounceEvent( function () {
        if ( ev.target.innerText.toLowerCase() == "block" ||
          ev.target.innerText.toLowerCase() == "unblock" ){
            recordBlockBtnAction();
          }

        }, 200);

    };

    /*
    * UPDATE THE FOLLOW REQUEST FOR THE USER
    * 1. REVERT THE BOOLEAN FLAG
    * 2. SEND API REQUEST TO UPDATE
    */
    const recordBlockBtnAction = () => {

      cloneAuthor.value.block.is_blocked = !cloneAuthor.value.block.is_blocked;

      API.recordAction( {
        action: cloneAuthor.value.block.is_blocked ? "block" : "unblock",
        object: "user",
        id: cloneAuthor.value.id,
      } ).then(
        () => {},
        ( error ) => console.log( error.response.data.message )
      );

    };

    /*
    * RETURNS BOOLEAN FLAG IF THE LOGGED IN USER
    * IS VIEWING ITS OWN PROFILE
    */
    const isCurrentUser = () =>
      props.author && props.author.id && props.author.id == getCurrentUserID() ?
      true : false;



    const followBtnClicked = ( followFlag ) => {

      cloneAuthor.value.follow.no_followers = parseInt( cloneAuthor.value.follow.no_followers );
      if ( followFlag )
        cloneAuthor.value.follow.no_followers++;
      else if ( cloneAuthor.value.follow.no_followers > 0 )
        cloneAuthor.value.follow.no_followers--;

      emit( 'followBtnClicked', cloneAuthor.value.follow.no_followers )

    }

    return{
      getCurrentUserID,
      isCurrentUser,
      cloneAuthor,
      followBtnText,
      blockBtnText,
      handleClickBtn,
      recordBlockBtnAction,
      followBtnClicked
    }

  }
};
</script>
