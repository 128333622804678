<template>
  <div
    v-if="drafts.length"
    class="bg-lightest-gray p-4 -mx-4 lg:-mx-12 lg:px-12"
  >
    <h4 class="draft-title font-2xl my-4 relative text-center overflow-hidden">
      <span class="px-3">Drafts</span>
    </h4>
    <ul class="">
      <li
        v-for="post in drafts"
        class="mb-2 post-content flex justify-between mb-6"
        :post="post"
        :key="post"
        @deletePost="deletePost"
      >
        <h4 class="font-bold" v-html="post.title.rendered"></h4>
        <div class="w-48 text-right">
          <EditPostActions :post="post" :key="post" @deletePost="deletePost" />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import EditPostActions from "@/templates/post-list/EditPostActions.vue";

export default {
  props: {
    posts: Array,
    status: String,
  },
  data() {
    return { drafts: [] };
  },
  components: {
    EditPostActions,
  },
  methods: {
    deletePost(draftID) {
      this.drafts = this.drafts.filter( ( draft ) => draft.id != draftID );
    },
  },
  watch: {
    posts: {
      handler() {
        this.drafts = this.posts;
      },
      immediate: true,
    },
  },
};
</script>
<style>
.draft-title::before,
.draft-title::after {
  content: "";
  height: 1px;
  @apply bg-black inline-block relative w-6/12 align-middle;
}

.draft-title::before {
  margin-left: -50%;
}

.draft-title::after {
  margin-right: -50%;
}

.four-grid .thumbnail-bg {
  height: 200px;
}
.four-grid .post-content {
  min-height: 120px;
}
.four-grid .author-avatar-parent {
  @apply hidden;
}
.four-grid .author-name {
  @apply font-normal;
}
</style>
