<template>

  <div class="my-2">
    <div
      contenteditable="true"
      placeholder="Start writing…"
      ref="commentContent"
      class="block w-full p-4 bg-lightest-gray text-black resize-none outline-none"
      style="min-height:100px;"
    ></div>

    <ul class="comment-submit flex items-center my-6">
      <li>
        <button
          class="btn-comment-respond"
          @click="handleRespond"
          v-html="respondBtnText()">
        </button>
      </li>
      <li class="mx-3">or</li>
      <li class="cancel-reply">
        <button
          class="text-base font-semibold text-yka-blue"
          @click="handleCancel"
        >
          Cancel
        </button>
      </li>
    </ul>
  </div>
</template>
<script>

//import store from "@/store";

import API from "@/api";

import {ref, onMounted} from "vue";

export default{
  props:{
    params: Object
  },
  setup( props, { emit } ){

    const processing = ref( false );

    const commentContent = ref( "" );

    const respondBtnText = () => processing.value ? "Responding..." : "Respond";

    onMounted( () => {
      if( props.params.content )
        commentContent.value.innerHTML = props.params.content;
    } );

    const handleRespond = () => {

      if ( commentContent.value.innerHTML != "" ) {

        processing.value = true;

        const commentParams = props.params;

        commentParams.content = commentContent.value.innerHTML;

        API.createComment( commentParams ).then(
          ( response ) => {

            processing.value = false;

            commentContent.value.innerHTML = "";

            // SHOW PENDING MESSAGE IF STATUS IS SET TO HOLD
            if ( response.data.status === "hold" ) {
              var message = "Your comment has been submitted for moderation. It will appear once it has been approved.";
              alert( message );
              return;
            }

            //console.log( response.data );

            emit( "respond", commentParams.content );

          },
          (error) => {

            processing.value = false;

            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              alert( error.response.data.message );
            }
          }
        );
      }


    }

    const handleCancel = () => emit( "cancel" );


    return {
      processing,
      respondBtnText,
      commentContent,
      handleCancel,
      handleRespond
    }

  }
}
</script>
