<template>
  <nav class="footer-menu-nav" v-if='settings.menus && settings.menus.footer'>
    <ul class="footer-menu">
      <li v-for="(item, key) in settings.menus.footer" :key="key">
        <NavLink
          v-if="item.tag == 'a'"
          :href="item.path"
          :linkText="item.title"
          :tag="item.tag"
        />
        <NavLink
          v-if="item.tag == 'router-link'"
          :to="item.path"
          :linkText="item.title"
          :tag="item.tag"
        />
      </li>
    </ul>
  </nav>
</template>
<script>
import NavLink from "@/components/NavLink.vue";

import Settings from '@/lib/Settings';

export default {
  components: {
    NavLink,
  },
  setup(){

    const {settings} = Settings()

    return {
      settings
    }

  }
};
</script>
<style>
.right-sidebar .footer-menu li {
  @apply inline text-xs mr-2 text-gray;
}
</style>
