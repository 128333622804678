import { createRouter, createWebHistory } from "vue-router";

import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/Home.vue"),
  },
  {
    path: "/search",
    name: "Search",
    //component: Search
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/Search.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/Logout.vue"),
  },
  {
    path: "/:year(\\d+)/:month(\\d+)/:slug",
    name: "SinglePost",
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/SinglePost.vue"),
  },

  {
    path: "/author/:slug",
    name: "Author",
    //component: FEP
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/Author.vue"),
  },

  {
    path: "/video/:slug",
    name: "SingleVideo",
    //component: FEP
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/Video.vue"),
  },
  {
    path: "/:slug",
    name: "Page",
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/Page.vue"),
  },

  {
    path: "/:taxonomy/:slug",
    name: "Archive",
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/Archive.vue"),
  },
  {
    path: "/:taxonomy/:parent/:slug",
    name: "SubCategory",
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/Archive.vue"),
  },
  {
    path: "/poll/:slug",
    name: "SinglePoll",
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/SinglePoll.vue"),
  },
  {
    path: "/note/:slug",
    name: "SingleNote",
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/SingleNote.vue"),
  },
  {
    path: "/polls-list",
    name: "PollsList",
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/FilterPolls.vue"),
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/PrivatePage.vue"),
    children: [
      {
        path: "/edit",
        name: "EditProfile",
        component: () =>
          import(/* webpackChunkName: "FEPUser" */ "@/views/EditProfile.vue"),
      },
      {
        path: "/newsfeed",
        name: "Newsfeed",
        component: () =>
          import(/* webpackChunkName: "Chunks1" */ "@/views/Newsfeed.vue"),
      },
      {
        path: "/me",
        name: "FEP",
        component: () =>
          import(/* webpackChunkName: "FEPUser" */ "@/views/FEP.vue"),
      },
      {
        path: "create-poll",
        name: "CreatePoll",
        component: () =>
          import(/* webpackChunkName: "Chunks1" */ "@/views/CreatePoll.vue"),
      },
      {
        path: "create-note",
        name: "CreateNote",
        component: () =>
          import(/* webpackChunkName: "Chunks1" */ "@/views/CreateNote.vue"),
      },
      {
        path: "write",
        name: "CommonWritingPage",
        component: () =>
          import(
            /* webpackChunkName: "Chunks1" */ "@/views/CommonWritingPage.vue"
          ),
      },
      {
        path: "/mystatefeed",
        name: "MyStateFeed",
        component: () =>
          import(/* webpackChunkName: "Chunks1" */ "@/views/MyStateFeed.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "Chunks1" */ "@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      // BEFORE:
      return { el: to.hash };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.name) {
    store.commit("setProcessing", true);
    // anymindTS does not load on initial page-load. Hence, it's not disposed off
    // anymindTS.dispose works on subsequent route changes
    window.anymindTS && window.anymindTS.dispose && window.anymindTS.dispose();
  }
  next();
});

router.afterEach(() => {
  store.commit("setProcessing", false);
  document.getElementById("app").scrollIntoView();

  // GOOGLE ANALYTICS
  if (typeof ga === "function") {
    // eslint-disable-line no-undef
    ga("send", "pageview"); // eslint-disable-line no-undef
  }
  if (typeof dataLayer === "object") {
    // eslint-disable-line no-undef
    dataLayer.push({ event: "pageview" }); // eslint-disable-line no-undef
  }

  // Start AnymindTS after a page has been loaded
  window.startAnymindTS && window.startAnymindTS();
});

export default router;
