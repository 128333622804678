export default {
  namespaced: true,
  state: {
    showModal : false,
    params    : {}
  },
  mutations: {
    showModal( state, payload ){
      state.showModal = payload;
    },
    setParams( state, payload ){
      payload.style = "PollWithinModal";
      state.params = payload;
    },
  },
};
