<template>
  <component :is="params.style" :term="term" :status="status"></component>
</template>

<script>
import { computed } from "vue";
import { useQuery } from "vue-query";
import API from "@/api.js";
import Util from "@/lib/Util";
import TermName from "@/templates/term/Name.vue";

export default {
  props: {
    params: Object,
  },
  components: {
    TermName,
  },

  setup(props) {
    const params = computed(() => {
      var params = Util.removeEmptyParams(
        JSON.parse(JSON.stringify(props.params))
      );

      if (!params.taxonomy) {
        params.taxonomy = "categories";
      }

      return params;
    });

    //console.log( params.value )

    //const taxonomy = ref( 'categories' );

    //if( props.params.taxonomy )
    //taxonomy.value = props.params.taxonomy

    function getTerm() {
      return API.requestTerm(props.params.id, params.value.taxonomy);
    }

    const { status, data } = useQuery("termQuery" + props.params.id, getTerm);

    const term = computed(() =>
      data.value && data.value.data ? data.value.data : {}
    );

    return {
      term,
      status,
    };
  },
};
</script>
