<template>
  <div class="dropdown-menu relative">

    <div @click="toggleDropdownMenu()">
      <slot name="dropdown" />
    </div>
    <div class="dropdown-submenu" v-if="openDropdown">
      <ul>

        <li class="menu-item">

          <template v-for="button in buttons" :key="button.title">
            <div
              class="cursor-pointer item"
              v-if="!button.isExternalLink"
              @click="handleButtonClick( button.icon )"
            >
              <Icon :name="button.icon" class="h-6 w-6 inline-block mr-2" />
              <span class="inline-block">{{ button.title }}</span>
            </div>
            <a class="item" :href="button.link" target="_blank" v-else>
              <Icon :name="button.icon" class="h-6 w-6 inline-block mr-2" />
              <span class="inline-block">{{ button.title }}</span>
            </a>
          </template>

        </li>

      </ul>
    </div>
  </div>
</template>

<script>

import {ref} from "vue";
import Icon from "@/components/Icons.vue";

export default {
  props: {
    buttons : Array
  },
  components: {
    Icon,
  },

  setup( props, { emit } ){

    const openDropdown = ref( false );

    const toggleDropdownMenu = () => {
      //console.log( 'dropdown menu is clicked' );
      openDropdown.value = !openDropdown.value;
      if ( openDropdown.value ) {
        document.addEventListener( "click", closeWhenClickedOutside );
      }
    };

    const closeWhenClickedOutside = ( event ) => {
      if ( !event.target.closest( ".dropdown-menu" ) ) {
        openDropdown.value = false;
        document.removeEventListener( "click", closeWhenClickedOutside );
      }
    }

    const handleButtonClick = ( icon ) => {
      openDropdown.value = false;
      emit( 'dropdownSelected', icon );
    }

    return {
      openDropdown,
      toggleDropdownMenu,
      closeWhenClickedOutside,
      handleButtonClick
    }

  }
};
</script>
<style scoped>

</style>
