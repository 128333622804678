<template>
  <div class="feed">
    <div class="feed-featured">
      <OrbitPosts
        :params="getParams('featured')"
        :key="getParams('featured')"
        @totalItemsUpdated="onTotalItemsUpdate"
      >
        <template v-slot:whenempty>
          <p class="text-gray font-thin mb-8 mt-2">
            Curate your newsfeed. Start by exploring and following the top users
            on Youth Ki Awaaz.
          </p>
        </template>
      </OrbitPosts>
    </div>

    <div class="feed-polls" :class="totalPolls > 0 ? 'stretch-container' : ''">
      <OrbitPosts
        :params="getParams('polls')"
        :key="getParams('polls')"
        @totalItemsUpdated="onTotalPollsUpdate"
      >
        <template v-slot:title>
          <div
            class="flex flex-wrap gap-3 text-lg justify-between items-center sm:border-b sm:pb-2 border-lighter-gray"
          >
            <div>
              <div class="text-lg">Featured<strong>Polls</strong></div>
              <div class="text-sm text-gray">
                Your awaaz on things that matter.
              </div>
            </div>
            <div>
              <!-- Button -->
              <a
                v-if="isUserLoggedIn"
                href="#take-poll"
                @click="openPollSlides"
                class="button bg-yka-blue text-white mr-3"
                >Take a poll</a
              >
              <router-link
                v-if="isUserLoggedIn"
                :to="{ name: 'CreatePoll' }"
                class="button bg-white text-yka-blue"
              >
                Create Poll
              </router-link>
            </div>
          </div>
        </template>
      </OrbitPosts>
    </div>

    <div
      class="pb-4 mb-8 border-b border-lightest-gray"
      v-if="!totalPolls"
    ></div>

    <div class="feed-body" v-if="totalItems">
      <OrbitPosts :params="getParams('body')" :key="getParams('body')" />
    </div>
  </div>
</template>
<script>
import Util from "@/lib/Util";

import { computed, ref } from "vue";

import store from "@/store";

export default {
  props: {
    params: Object,
  },
  components: {},
  setup(props) {
    const totalItems = ref(0);

    const totalPolls = ref(0);

    const onTotalItemsUpdate = (total) => (totalItems.value = total);

    const onTotalPollsUpdate = (total) => (totalPolls.value = total);

    const getParams = (key) => {
      var params = Util.convertToObject(props.params);

      switch (key) {
        case "featured":
          params.post_type = "posts";
          params.per_page = 1;
          params.page = 1;
          params.style = "PostList";
          params.pagination = 0;
          break;

        case "polls":
          params.post_type = "poll";
          params.style = "PollScroll";
          if (store?.state?.localSettings?.id)
            params.not_voted_by = store.state.localSettings.id;
          break;

        case "body":
          params.post_type = "posts";
          params.per_page = 10;
          params.page = 1;
          params.style = "PostList";
          params.pagination = 1;
          break;
      }

      //console.log( params );

      return params;
    };

    const openPollSlides = (ev) => {
      ev.preventDefault();
      store.commit("pollSlides/showModal", true);

      store.commit("pollSlides/setParams", getParams("polls"));
    };

    const isUserLoggedIn = computed(() => {
      if (store.state.localSettings && store.state.localSettings.id)
        return true;
      return false;
    });

    return {
      getParams,
      totalItems,
      totalPolls,
      onTotalItemsUpdate,
      onTotalPollsUpdate,
      openPollSlides,
      isUserLoggedIn,
    };
  },
};
</script>

<style scoped>
.button {
  @apply border border-yka-blue text-center;
  @apply py-1 px-3 md:px-4 rounded text-xs font-medium;
}
</style>
