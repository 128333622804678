<template>
  <div
    :key="icon"
    class="inline-block text-sm hover:text-black cursor-pointer">
    <Icons :name="icon" class="h-5 w-5 inline" />
    <span v-if='label' class="ml-1" v-html='label'></span>
  </div>
</template>

<script>

import Icons from "@/components/Icons.vue";

export default{
  props:{
    icon: String,
    label: String
  },
  components:{
    Icons
  }
}
</script>
