<template>
  <router-link :to="getPostLink(post)" class="block mb-4 relative flex">
    <div
      v-if="post.featured_image"
      class="w-10 h-10 block object-cover bg-center bg-gray bg-no-repeat bg-cover mr-2"
      :style="{ backgroundImage: `url(${post.featured_image})` }"
    ></div>
    <div class="w-full -mt-1">
      <div
        class="text-sm leading-tight font-medium line-clamp-2"
        v-if="post.title"
        v-html="post.title.rendered"
      ></div>
      <div
        class="text-mid-gray text-xs line-clamp-2"
        v-if="post.excerpt"
        v-html="post.excerpt.rendered"
      ></div>
    </div>
  </router-link>
</template>

<script>
import Util from "@/lib/Util";

export default {
  props: {
    post: Object,
  },
  methods: {
    getPostLink: (post) => Util.getPostLink(post),
  },
};
</script>
