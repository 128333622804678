export default {
  namespaced: true,
  state: {
    showModal: false,
    email: "",
    page: 1,
    inputOtp: "",
    sentOtp: "",
    processing: false,
    redirect: "",
    redirectPath: "",
    errorMsg: "",
  },
  mutations: {
    showModal(state, payload) {
      state.showModal = payload;
    },
    setEmail(state, payload) {
      state.email = payload;
    },
    setInputOtp(state, payload) {
      state.inputOtp = payload;
    },
    setSentOtp(state, payload) {
      state.sentOtp = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setProcessing(state, payload) {
      state.processing = payload;
    },
    setRedirect(state, payload) {
      state.redirect = payload;
    },
    setRedirectPath(state, payload) {
      state.redirectPath = payload;
    },
    setErrorMsg(state, payload) {
      state.errorMsg = payload;
    },
  },
};
