<template>
  <router-link v-if="isUserLoggedIn" :to="redirectParams" :class="btnCss">
    {{ btnText }}
  </router-link>
  <a
    v-else
    href="#"
    :class="btnCss"
    data-behaviour="login-modal-redirect-route"
    :data-redirect-path="btnPath"
    :data-redirect-path-name="btnPathName"
  >
    {{ btnText }}
  </a>
</template>
<script>
import store from "@/store";
import { computed, ref } from "vue";

export default {
  name: "LoginRedirectButton",
  props: {
    params: Object,
  },
  setup(props) {
    const isUserLoggedIn = computed(() => {
      return Boolean(
        store.state.localSettings && store.state.localSettings.id
          ? store.state.localSettings.id
          : 0
      );
    });

    const btnText = props.params.text ? props.params.text : "Add Text";

    const btnCss = [
      props.params.css ? props.params.css : "inline-block underline",
    ];

    const redirectToPath = ref(props.params.path_name && props.params.path);

    const btnPathName = ref(
      props.params.path_name ? props.params.path_name : "Newsfeed"
    );

    const btnPath = ref(redirectToPath.value ? props.params.path : null);

    const redirectParams = {
      ...(redirectToPath.value
        ? { path: btnPath.value }
        : { name: btnPathName.value }),
    };

    return {
      btnCss,
      btnPath,
      btnText,
      btnPathName,
      redirectParams,
      isUserLoggedIn,
    };
  },
};
</script>
