<template>
  <component
    :is="params.style"
    :author="author"
    :status="status"
  />
</template>

<script>
import { useQuery } from "vue-query";
import { computed } from "vue";
import API from "@/api.js";
import AuthorName from "@/templates/author/Name.vue";
import AuthorAvatar from "@/templates/author/Avatar.vue";
import AuthorInfo from "@/templates/author/AuthorInfo.vue";
import AuthorPostBio from "@/templates/author/PostBio.vue"

export default {
  props: {
    params: Object,
  },
  components: {
    AuthorName,
    AuthorAvatar,
    AuthorInfo,
    AuthorPostBio
  },
  setup(props) {
    const getAuthor = () => API.requestUser( props.params.id );

    const { status, data } = useQuery(
      "authorQuery" + props.params.id,
      getAuthor
    );

    const author = computed( () =>
      data.value && data.value.data ? data.value.data : {}
    );

    return {
      author,
      status,
    };
  },
};
</script>
