<template>
  <article>
    <router-link :to='getPostLink( post )' class="absolute top-0 left-0 z-10 w-full h-full"></router-link>
    <div
      class="grid-bg relative bg-no-repeat bg-cover bg-center"
      :style="{ backgroundImage: `url(${post.featured_image})` }"
    ></div>
    <div class="grid-content w-full h-full absolute top-0 left-0">
      <div class="text-lg capitalize" v-html="post.title.rendered"></div>
    </div>
    <div
      class="grid-footer flex items-center absolute bottom-0 w-full"
      :class="[showPostViewCount ? 'justify-between' : 'justify-end']"
    >
      <div class="post-view" v-if="showPostViewCount">
        {{ postViewCount }}+ writing
      </div>
      <a
        :href="`${post.link}+'promp-link'`"
        class="thumb-writing-btn text-black"
      >
        Start Writing
      </a>
    </div>
  </article>
</template>

<script>
import DefaultMixin from '@/mixins/DefaultMixin.js'
import Util from '@/lib/Util.js'

export default {
  name: "PromptItem",
  mixins: [ DefaultMixin ],
  props: {
    post: Object,
  },
  computed: {
    postViewCount() {
      return this.post.post_view_count;
    },
    showPostViewCount() {
      const limit = 10;
      return this.postViewCount > limit;
    },
  },
  methods: {
    getPostLink: ( post ) => Util.getPostLink( post )
  }
};
</script>

<style>
.prompt-grid {
  grid-gap: 30px;
  grid-template-columns: 1fr;
}
.prompt-grid-item {
  max-height: 290px;
}
.prompt-grid-item .grid-bg {
  min-height: 290px;
}

.prompt-grid-item .grid-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 30px;
}

.prompt-grid-item .grid-footer {
  padding: 0 30px 20px;
}

.grid-footer .post-view {
  border: 2px solid #f6f6f6;
  border-radius: 3px;
  padding: 3px 6px;
}
.thumb-writing-btn {
  padding: 5px 6px;
  border-radius: 3px;
  z-index: 10;
  background-color: #f6f6f6;
}
@media (min-width: 768px) {
  .prompt-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 238px 238px;
    grid-gap: 24px;
  }
  .prompt-grid-item:nth-child(3n + 1) {
    max-height: 500px;
    grid-column: auto;
    grid-row: auto/span 2;
  }

  .prompt-grid-item:nth-child(3n),
  .prompt-grid-item:nth-child(3n + 2) {
    max-height: 238px;
  }
  .prompt-grid-item:nth-child(3n + 1) .grid-bg {
    min-height: 500px;
  }
  .prompt-grid-item:nth-child(3n),
  .prompt-grid-item:nth-child(3n + 2) {
    min-height: 238px;
  }
}
</style>
