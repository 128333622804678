import API from '@/api'

const cf7 = () => {

  var formSelector = "form.wpcf7-form"

  const showFormSubmissionMessage = ( form, message ) => {
    var element = document.createElement('div');
    element.innerHTML = message;
    element.classList.add( 'wpcf7-message' );
    form.parentElement.append( element );
    form.remove();
  }

  const formSubmissionHandler = ( ev ) => {
    ev.preventDefault();
    const formElement = ev.target;
    const body = new FormData( formElement );
    var form_id = formElement.elements['_wpcf7'].value;
    API.requestCF7( form_id, body ).then( ( response ) => {
      showFormSubmissionMessage( formElement, response.data.message );
    } )
  }

  const enableCF7 = () => {
    var formElement = document.querySelector( formSelector );
    if( formElement ){
      formElement.addEventListener( "submit", formSubmissionHandler );
    }
  }

  const disableCF7 = () => {
    var formElement = document.querySelector( formSelector );
    if( formElement ){
      formElement.removeEventListener( "submit", formSubmissionHandler );
    }
  }

  return {
    enableCF7,
    disableCF7
  }

}

export default cf7
