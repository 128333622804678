<template>
  <div class="orbit-posts" ref="scrollComponent">
    <slot name="title" v-if="items.length"></slot>
    <component
      v-bind="$attrs"
      :is="params.style"
      :posts="items"
      :status="status"
    ></component>
    <slot name="whenempty" v-if="!items.length && status == 'success'"></slot>
    <button
      v-if="showLoadMore"
      @click="handleLoadMore()"
      class="px-4 py-2 text-white"
      :class="isFetchingNextPage && 'cursor-not-allowed opacity-50'"
      :style="{ backgroundColor: '#000000' }"
      :disabled="isFetchingNextPage"
    >
      Load More
    </button>
  </div>
</template>
<script>
import { onMounted, onUnmounted, computed, watch } from "vue";
import API from "@/api";
import Util from "@/lib/Util";
import OrbitQuery from "@/lib/OrbitQuery";
import PostList from "@/templates/PostList.vue";
import TwoGrid from "@/templates/TwoGrid.vue";
import PollFeed from "@/templates/PollFeed.vue";
import VideoGrid from "@/templates/VideoGrid.vue";
import EditPosts from "@/templates/EditPosts.vue";
import DraftPosts from "@/templates/DraftPosts.vue";
import PromptGrid from "@/templates/PromptGrid.vue";
import RelatedPosts from "@/templates/RelatedPosts.vue";
import TrendingPosts from "@/templates/TrendingPosts.vue";
import RelatedTwoGrid from "@/templates/RelatedTwoGrid.vue";

import PostListTwoGrid from "@/templates/PostListTwoGrid.vue";
import PollFeedTwoGrid from "@/templates/PollFeedTwoGrid.vue";
import PollFeedThreeGrid from "@/templates/PollFeedThreeGrid.vue";
import PollSingleFeed from "@/templates/polls/PollSingleFeed.vue";
import PollScroll from "@/templates/polls/PollScroll.vue";
import PollWithinModal from "@/templates/polls/PollWithinModal.vue";

export default {
  props: {
    params: Object,
  },
  components: {
    PromptGrid,
    VideoGrid,
    PostList,
    PostListTwoGrid,
    TrendingPosts,
    RelatedPosts,
    RelatedTwoGrid,
    TwoGrid,
    EditPosts,
    DraftPosts,
    PollWithinModal,
    PollFeed,
    PollFeedTwoGrid,
    PollFeedThreeGrid,
    PollSingleFeed,
    PollScroll,
  },
  setup(props, { emit }) {
    //console.log( props.params );

    const params = computed(() => {
      var params = Util.removeEmptyParams(
        JSON.parse(JSON.stringify(props.params))
      );
      if (params.style == "TrendingPosts") {
        params.trending = 1;
      }

      delete params["style"];
      params.post_type = params.post_type ? params.post_type : "posts";
      return params;
    });

    //console.log( params.value );

    const requestAPI = (params) => {
      //console.log( params )
      if (params?.feed == "newsfeed")
        return API.requestNewsfeed(params.post_type, params);

      return API.requestPosts(params.post_type, params);
    };

    const {
      items,
      watchScroll,
      scrollComponent,
      status,
      loadMore,
      hasMorePages,
      isFetchingNextPage,
      totalItems,
    } = OrbitQuery(params.value, requestAPI);

    /*
     * SEND THE UPDATED TOTAL OF ITEMS TO THE PARENT
     */
    watch(items, () => emit("totalItemsUpdated", totalItems.value));

    const pagination_style = computed(() =>
      props.params.pagination_style ? props.params.pagination_style : "default"
    );

    const showLoadMore = computed(() => {
      return (
        status.value === "success" &&
        items.value.length &&
        pagination_style.value === "loadMore" &&
        hasMorePages.value
      );
    });

    const handleScroll = () => {
      Util.debounceEvent(function () {
        watchScroll();
      }, 50);
    };

    const handleLoadMore = () => {
      Util.debounceEvent(function () {
        loadMore();
      }, 250);
    };

    onMounted(() => {
      emit("totalItemsUpdated", totalItems.value);

      //getItems();
      if (props.params.pagination && pagination_style.value === "default") {
        window.addEventListener("scroll", handleScroll);
      }
    });

    onUnmounted(() => {
      if (props.params.pagination && pagination_style.value === "default") {
        window.removeEventListener("scroll", handleScroll);
      }
    });

    return {
      items,
      //enablePagination,
      status,
      showLoadMore,
      handleLoadMore,
      scrollComponent,
      isFetchingNextPage,
    };
  },
};
</script>
