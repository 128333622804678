<template>
  <li :id="`comment-${comment.id}`" class="comment" v-if="comment.author">
    <div
      class="flex bg-white align-middle flex-col my-3 py-5"
      v-if="'show' == mode"
    >
      <div class="flex-row flex-1 flex gap-4 mb-4 w-full relative">
        <OrbitAuthor
          :params="{ style: 'AuthorAvatar', id: comment.author }"
          class="w-12 h-12"
        />
        <div>
          <OrbitAuthor
            class="font-semibold text-left capitalize"
            :params="{ style: 'AuthorName', id: comment.author }"
          />
          <div class="text-xs" v-html="formatDate(comment.date)"></div>
        </div>

        <div class="absolute right-0 text-sm" v-if="isCurrentAuthor()">
          <DropdownMenu
            @dropdownSelected="handleAction"
            :buttons="actionMenuItems"
          >
            <template v-slot:dropdown>
              <IconBtn icon="ellipsis-horizontal" />
            </template>
          </DropdownMenu>
        </div>
      </div>
      <div class="flex flex-col">
        <div
          class="comment-content text-left text-base text-black leading-tight mb-4"
          v-html="cloneContent"
        ></div>
        <button
          v-if="!comment.parent && !openReplyForm && $store.state.comments.status && $store.state.localSettings"
          class="text-sm font-semibold text-yka-blue text-left"
          @click="handleReply"
        >
          Reply
        </button>
        <CommentInput
          @respond="postReply"
          @cancel="cancelReply"
          :params="{ post: comment.post, parent: comment.id }"
          v-if="!comment.parent && openReplyForm"
        />
      </div>
    </div>

    <CommentInput
      @respond="postEdit"
      @cancel="cancelEdit"
      :params="{
        id: comment.id,
        content: cloneContent,
      }"
      v-else
    />

    <OrbitComments
      v-if="uniqueKey"
      :key="uniqueKey"
      class="pl-10"
      :post_id="comment.post"
      :parent_id="comment.id"
    />
  </li>
</template>

<script>
import CommentInput from "@/templates/comment/input.vue";
import OrbitAuthor from "@/lib/OrbitAuthor.vue";

import DropdownMenu from "@/components/DropdownMenu.vue";
import IconBtn from "@/components/IconBtn.vue";

import API from "@/api";
import store from "@/store";

import Util from "@/lib/Util";

import { ref } from "vue";

export default {
  props: {
    comment: Object,
  },
  components: {
    OrbitAuthor,
    CommentInput,
    IconBtn,
    DropdownMenu,
  },
  setup(props, { emit }) {
    const cloneContent = ref(props.comment.content.rendered);

    const mode = ref("show");

    const uniqueKey = ref(props.comment.total_children);

    const openReplyForm = ref(false);

    const handleReply = () => (openReplyForm.value = true);

    const cancelReply = () => (openReplyForm.value = false);

    const postReply = () => {
      openReplyForm.value = false;
      uniqueKey.value++;
    };

    const postEdit = (content) => {
      mode.value = "show";
      cloneContent.value = content;
    };

    const cancelEdit = () => {
      mode.value = "show";
    };

    const handleAction = (icon) => {
      switch (icon) {
        case "pencil-square":
          mode.value = "edit";
          break;
        case "trash":
          if (confirm("Are you sure you want to delete this?")) {
            API.deleteComment(props.comment.id).then(
              () => emit("deleteComment", props.comment.id),
              (error) => console.log(error.response.data.message)
            );
          }
          break;
      }
    };

    const isCurrentAuthor = () =>
      props.comment.author == store.state.localSettings?.id ? true : false;

    const formatDate = (dateString) => Util.formatDate(dateString);

    const actionMenuItems = ref([
      {
        title: "Edit",
        icon: "pencil-square",
      },
      {
        title: "Delete",
        icon: "trash",
      },
    ]);

    return {
      cloneContent,
      uniqueKey,
      handleReply,
      postReply,
      cancelReply,
      openReplyForm,
      formatDate,
      actionMenuItems,
      mode,
      postEdit,
      cancelEdit,
      handleAction,
      isCurrentAuthor,
    };
  },
};
</script>
